import React, {PureComponent} from "react";
import {Container, Grid} from '@material-ui/core';

import {BaseProps} from "screens/index.d";
import {ISetting} from "store/models/index.d";
import Filter from "./extra/filter";
import Table from "components/table-virtualized";
import TableDialog from "components/table-dialog";
import DialogMessage from "components/dialogMessage";

export interface GeneralProps extends BaseProps {
    setting: ISetting,
    fnSaveMediaPlan: any,
}

export interface GeneralState {
    dialog: boolean,
    dialogMessage: {
        open: boolean,
        title: string,
        message: string
    }
}

export class GeneralEditMediaPlan<T extends GeneralProps, S extends GeneralState> extends PureComponent<T, S> {

    handleTableDialog = (type: boolean) => this.setState({dialog: type})

    fnSaveMediaPlan = (type: string) => {
        const self = this;
        self.props.fnSaveMediaPlan(type, (data: any) => {
            self.setState({dialog: false}, () => {
                const {message, title, redirect} = data;
                if (redirect) window.open(redirect);
                else if (message && title) {
                    self.setState({dialogMessage: {
                        open: true,
                        message, title
                    }})
                }
            })
        })
    }

    handleCloseDialogMessage = () => {
        const {dialogMessage} = this.state;
        this.setState({dialogMessage: {
            ...dialogMessage,
            open: false
        }})
    }

    renderContent = (title: string, isEdit: boolean = false) => {
        const self = this,
        {dialogMessage} = self.state,
        {setting} = self.props;

        return (
            <div id="page-calculateMediaPlan">
                <Container fixed>
                    <Grid
                        className="grid-title" container
                        justify="center" alignItems="center"
                        spacing={0}>
                        <Grid item xs={8}>
                            <div className="title">
                                {title}
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="link-to-instruction">
                                {setting.instruction?(
                                    <a href={setting.instruction} title="Инструкция" target="_blank" rel="noopener noreferrer">
                                        Инструкция
                                    </a>
                                ):null}
                            </div>
                        </Grid>
                    </Grid>
                </Container>
                <Container fixed>
                    <Grid
                        container spacing={0}>
                        <Grid item xs={12}>
                            <Filter isEdit={isEdit} />
                        </Grid>
                    </Grid>
                </Container>
                <Grid
                    container spacing={0}>
                    <Grid item xs={12}>
                        <Table
                            handleTableDialog={self.handleTableDialog}/>
                    </Grid>
                </Grid>
                <TableDialog
                    open={self.state.dialog}
                    handlerDialog={self.handleTableDialog}
                    fnSaveMediaPlan={self.fnSaveMediaPlan}/>
                <DialogMessage
                    open={dialogMessage.open}
                    title={dialogMessage.title}
                    message={dialogMessage.message}
                    handleClose={self.handleCloseDialogMessage}/>
            </div>
        )
    }

    render = () => {
        return this.renderContent("Рассчет медиаплана");
    }
}
