import React from "react";
import {connect} from "react-redux";
import {CircularProgress, Container, Grid} from "@material-ui/core";
import lodash from "lodash";
import {BaseProps} from "screens";
import {TMeta, TPage} from "store/models/index.d";
import Contacts from "../contacts"

type TPageScreen = {
    pages: TPage[],
    meta: TMeta[],

    pageUri?: string,
    titleStyles?: React.CSSProperties,
    descriptionStyles?: React.CSSProperties,
    containerStyles?: React.CSSProperties,
} & BaseProps

type TState = {
    item: TPage | undefined,
    metadata: TMeta | undefined,
    loading: boolean
}

class PageScreen extends React.PureComponent<TPageScreen, TState> {

    readonly state: TState = {
        item: undefined,
        metadata: undefined,
        loading: true
    }

    public static defaultProps = {
        pages: [],
        meta: [],
        pageUri: window.location.pathname,
        titleStyles: {},
        descriptionStyles: {},
        containerStyles: {},
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        this.getPage(window.location.pathname);
    }

    componentDidUpdate = (prevProps: TPageScreen) => {
        if (this.props.pages.length !== prevProps.pages.length) {
            this.getPage(window.location.pathname);
        }
    }

    getPage = (locationUri: string) => {
        const {pages, meta, pageUri} = this.props;
        let uri = pageUri === "/" ? locationUri : pageUri;

        if (pages.length > 0) {
            const key = lodash.findIndex(pages, p => `/${p.url}` === uri),
            keyMeta = lodash.findIndex(meta, m => m.uri === uri);

            if (key !== -1) {
                this.setState({
                    item: pages[key],
                    metadata: keyMeta !== -1 ? meta[keyMeta] : undefined,
                    loading: false
                })
            }
        }
    }

    render = () => {
        const {item, loading, metadata} = this.state,
        {containerStyles, titleStyles, descriptionStyles} = this.props;

        return (
            <div id="page" style={containerStyles}>
                {loading ? (
                    <div className="loading">
                        <CircularProgress />
                    </div>
                ): item !== undefined && (
                    <>
                         <Container fixed>
                            <Grid
                                className="grid-title" container
                                justify="center" alignItems="center"
                                spacing={0}>
                                <Grid item xs={12}>
                                    <div className="title" style={titleStyles}>
                                        {metadata && metadata.h1 ? metadata.h1 : item.name}
                                    </div>
                                </Grid>
                            </Grid>
                        </Container>
                        <Container fixed>
                            <Grid
                                className="grid-description" container>
                                <Grid item xs={12}>
                                    <div
                                        className="description"
                                        style={descriptionStyles}
                                        dangerouslySetInnerHTML={{
                                            __html: item.description
                                        }}></div>
                                </Grid>
                            </Grid>
                        </Container>
                        {item.showFeedback && <Contacts {...this.props} disabledBackground={true} disabledHeader={true} formTitle="Если остались вопросы, напишите нам!" formTitleSpan={12} visibleMap={false}/>}
                    </>
                )}
            </div>
        )
    }
}

const mapState = (state: any) => {
    return {
        pages: state.home.pages,
        meta: state.home.meta,
    };
}
export default connect(mapState)(PageScreen);