import React from "react";
import {connect} from "react-redux";
import {ICalculateMediaPlan_ChangesData as IChangesData, ICalculateMediaPlan_ChangeData} from "store/models/index.d";

type Props = {
    changesData: IChangesData,
    handleTableDialog: any
}

const Total: React.FC<Props> = ({changesData, handleTableDialog}) => {
    let totalPrace: number = 0, countSelected = 0;

    Object.keys(changesData).forEach((id: string) => {
        const data: ICalculateMediaPlan_ChangeData = changesData[Number(id)];
        if (data.selected) {
            countSelected += 1;
            totalPrace += data.price
        }
    });

    return (
        <div className="total-box">
            <div className="table-last-row">
                <div>
                    <div>Итоговый бюджет:</div>
                    <div>{totalPrace.toLocaleString('ru-RU')}</div>
                </div>
            </div>
            <div className="table-footer">
                <div className="selected-items">Выбрано позиций: <span>{countSelected}</span></div>
                <div className="budget">Бюджет: <span>{totalPrace.toLocaleString('ru-RU')} ₽</span></div>
                <div className="create">
                    <button
                        onClick={() => handleTableDialog(true)}
                        disabled={countSelected>0?false:true}>
                        Сформировать медиаплан
                    </button>
                </div>
            </div>
        </div>
    )
}

const mapState = (state: any) => {
    return {
        changesData: state.calculateMediaPlan.changesData,
    };
};

export default connect(mapState)(Total);
