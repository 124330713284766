import React, {Component} from "react";
import {Grid} from '@material-ui/core';
import VisibilitySensor from 'react-visibility-sensor';
import CountUp from 'react-countup';
import {IItemFigures} from "./index.d";

type State = {
    isVisible: boolean
}

class ItemFigures extends Component<IItemFigures, State> {

    readonly state = {
        isVisible: false
    }

    onVisibilityChange = (isVisible: boolean) => {
        if (isVisible && !this.state.isVisible) this.setState({isVisible});
    }

    render = () => {
        const self = this,
            {isVisible} = self.state,
            {amount, name} = self.props,
            countUpDefProps = {
                start: 1,
                end: amount,
                separator: " ",
                useEasing: true,
                redraw: true,
                duration: 3,
            }

        return (
            <VisibilitySensor
                offset={{top:300}}
                onChange={self.onVisibilityChange}>
                <Grid item xs={4}>
                    <div className="card">
                        <div className="amount">
                            {isVisible?(
                                <CountUp
                                    {...countUpDefProps}/>
                            ):countUpDefProps.start}
                        </div>
                        <div className="description">
                            {name}
                        </div>
                    </div>
                </Grid>
            </VisibilitySensor>
        )
    }
}

export default ItemFigures;
