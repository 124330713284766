import {IRoutes} from './index.d';

const routes: IRoutes = {
    home: {name: "Главная", to: "/"},
    calculateMediaPlan: {name: "Посчитать медиаплан", to: "/calculate-media-plan"},
    advertisingCatalog: {name: "Каталог рекламы", to: "/catalog"},
    // promotions: {name: "Акции", to: "/catalog-sale"},
    promotions: {name: "Кейсы", to: "/cases"},
    contacts: {name: "Контакты", to: "/contacts"},
}

export default routes;
