import React from 'react';
import {Link} from "react-router-dom";
import {Container, Grid} from '@material-ui/core';
import routes from "../../router/routes";

export type TSlider = {
    image: string,
    name: string,
    nameGreen: string,
    signature: string,
}

const Slider = ({image, name, nameGreen, signature}: TSlider) => {
    return (
        <section id="home-slider">
            <Container fixed>
                <Grid
                    className="grid-container"
                    container
                    justify="center"
                    alignItems="center"
                    spacing={3}>
                    <Grid item xs={5}>
                        <div className="description">
                            <div className="title">{name}</div>
                            <div className="title primary">{nameGreen}</div>
                            <div className="signature">{signature}</div>
                            <div className="button-content">
                                <Link to={routes.calculateMediaPlan.to} title="Посчитать медиаплан">
                                    Посчитать медиаплан
                                </Link>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={7}>
                        <div className="image">
                            <img src={image} alt={`${name} ${nameGreen} ${signature}`} />
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}
export default Slider;
