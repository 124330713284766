import React from "react";
import {Grid} from '@material-ui/core';
import ChangeGrid from './changeGrid';
import {PropsAuthorization} from "../index.d";
import Form from "./form";

const EnterForm = (props: PropsAuthorization) => {
    const {authorization} = props,
        {forms} = authorization,
        handleSubmit = (e: any) => {
            e.preventDefault();
            props.fnLogin();
        }

    return (
        <div>
            <div className="enter-form-title">
                Пожалуйста, войдите или зарегистрируйтесь,
                чтобы отправить заявку
            </div>
            <ChangeGrid
                fnAuthDialog={props.fnAuthDialog}
                formType={authorization.dialog.formType}/>
            <Form
                onSubmit={handleSubmit}
                valueChange={(name: string, value: string) => props.fnFormValueChange({form: 'login', name, value})}
                fields={forms.login}>
                <Grid
                    container alignItems="center" className="form-control">
                    <Grid item xs={6}>
                        <button type="button">Забыли пароль?</button>
                    </Grid>
                    <Grid item xs={6}>
                        <button type="submit">Войти</button>
                    </Grid>
                </Grid>
            </Form>
        </div>
    )
}

export default EnterForm;
