import {connect} from "react-redux";
import {GeneralEditMediaPlan, GeneralProps, GeneralState} from "generic/editMediaPlan";

class CalculateMediaPlan extends GeneralEditMediaPlan<GeneralProps, GeneralState> {

    readonly state = {
        dialog: false,
        dialogMessage: {
            open: false,
            title: "",
            message: "",
        }
    }
    
    componentDidMount = () => {
        window.scrollTo(0, 0);
    }

    render = () => {
        return this.renderContent("Расчет медиаплана")
    }
}

const mapState = (state: any) => {
    return {
        setting: state.setting
    };
}, mapDispatch = (dispatch: any) => {
    return {
        fnSaveMediaPlan: (type: any, callback: any) => dispatch.calculateMediaPlan.save({type, callback}),
    };
}

export default connect(mapState, mapDispatch)(CalculateMediaPlan);
