import React, {Component} from "react";
import {connect} from "react-redux";
import TableDialog from "components/table-dialog";
import {Portal, Backdrop, CircularProgress} from "@material-ui/core";
import {IResponse} from 'store/fetchUp';
import DialogMessage from "components/dialogMessage";

type Props = {
    id: number,
    visible: boolean,
    handleClose: any,
    fnGetMediaPlan: any,
    fnSetMediaPlan: any,
    fnSaveMediaPlan: any,
    updateTableMediaPlans: any,
}

type State = {
    loading: boolean,
    tableDialog: boolean,
    dialog: {
        open: boolean,
        title: string,
        message: string
    }
}

const defDialog = {
    open: false,
    title: "",
    message: ""
}

class ViewMediaPlan extends Component<Props, State> {

    readonly state = {
        loading: true,
        tableDialog: false,
        dialog: defDialog
    }

    static defaultProps = {
        id: 0,
        handleClose: () => {}
    }

    componentDidUpdate = (prevProps: Props) => {
        if (prevProps.visible !== this.props.visible) {
            return this.componentDidMount();
        }
    }

    componentDidMount = () => {
        const self = this,
        {visible, fnGetMediaPlan, fnSetMediaPlan} = self.props;

        if (visible) {
            fnGetMediaPlan(self.props.id, (response: IResponse, error: boolean) => {
                if (!error && response.status === 200) {
                    fnSetMediaPlan(response.data, () => {
                        self.setState({loading: false, tableDialog: true});
                    })
                } else self.handleCloseBackdrop();
            })
        }
    }

    fnSaveMediaPlan = (type: string) => {
        const self = this;
        self.props.fnSaveMediaPlan(type, (data: any) => {
            self.setState({tableDialog: false}, () => {
                self.props.updateTableMediaPlans();
                self.props.handleClose();
                const {message, title, redirect} = data;
                if (redirect) {
                    window.open(redirect);
                } else if (message && title) {
                    self.setState({dialog: {
                        open: true,
                        message, title
                    }})
                }
            })
        })
    }

    handleCloseBackdrop = () => {
        const self = this;
        self.setState({loading: false}, () => {
            self.props.handleClose()
        });
    }

    handleCloseDialogMessage = () => this.setState({dialog: defDialog})

    render = () => {
        const self = this,
            {loading, dialog, tableDialog} = self.state,
            {visible, handleClose} = self.props;
        return (
            <Portal>
                {visible?(
                    <>
                        {loading?(
                            <Backdrop
                                className="loading-backdrop"
                                onClick={self.handleCloseBackdrop}
                                open={loading}>
                                <CircularProgress color="primary" />
                            </Backdrop>
                        ):(
                            <TableDialog
                                saved={true}
                                open={tableDialog}
                                handlerDialog={handleClose}
                                fnSaveMediaPlan={self.fnSaveMediaPlan} />
                        )}
                    </>
                ):null}
                <DialogMessage
                    open={dialog.open}
                    title={dialog.title}
                    message={dialog.message}
                    handleClose={self.handleCloseBackdrop}/>
            </Portal>
        )
    }
}

const mapDispatch = (dispatch: any) => {
    return {
        fnGetMediaPlan: (id: any, callback: any) => dispatch.user.getMediaPlan({id, callback}),
        fnSetMediaPlan: (data: any, callback: any) => dispatch.calculateMediaPlan.setMediaPlan({data, callback}),
        fnSaveMediaPlan: (type: any, callback: any) => dispatch.calculateMediaPlan.save({type, callback}),
    };
}

export default connect(null, mapDispatch)(ViewMediaPlan);
