const ls = require('local-storage');

const _storeData = async (key, dataSource, callback) => {
    let name = '@KitchenMediaSite:' + key;
    try {
        ls.set(name, dataSource);
        if (callback) callback(true)
    } catch (error) {
        if (callback) callback(false)
    }
};

const _retrieveData = async (key, callback) => {
    let name = '@KitchenMediaSite:' + key;
    try {
        const value = ls(name);
        if (value !== null) {
            if (callback) callback(value, true)
        } else {
            if (callback) callback(null, false)
        }
    } catch (error) {
        if (callback) callback(null, false)
    }
};

const DB = {
    _storeData: _storeData,
    _retrieveData: _retrieveData,
}

export default DB;
