import React from "react";
import {Link} from "react-router-dom";
import {Container, Grid} from '@material-ui/core';
import routes from "../../router/routes";

interface IItemWorkInfo {
    image: string,
    name: string,
    signature: string,
}

interface IWorkInfo {
    title: string,
    signature: string,
    items: IItemWorkInfo[]
}

const ImageGrid = ({item}: {item: IItemWorkInfo}) => (
    <Grid item xs={9}>
        <img
            src={item.image}
            className="image"
            alt={`${item.name} ${item.signature}`}/>
    </Grid>
)

const DescriptionGrid = ({item, idx}: {item: IItemWorkInfo, idx: number}) => (
    <Grid item xs={3}>
        <div className="description-grid">
            <div className="number">
                <span className="circle">{idx + 1}</span>
            </div>
            <div className="name">{item.name}</div>
            <div className="signature">{item.signature}</div>
        </div>
    </Grid>
)

const WorkInfo = ({title, signature, items}: IWorkInfo) => {
    return (
        <section id="work-info">
            <div className="title-grid">
                <div className="title">{title}</div>
                <div className="signature">{signature}</div>
            </div>
            <Container fixed>
                {items.map((item: IItemWorkInfo, key: number) => {
                    let multipleOfTwo = (key % 2 === 0);
                    return (
                        <Grid
                            key={key}
                            container
                            alignItems="center"
                            className={`row-grid ${(multipleOfTwo?"right":"")}`}
                            spacing={3}>
                                {multipleOfTwo?(
                                    <>
                                         <DescriptionGrid item={item} idx={key} />
                                         <ImageGrid item={item} />
                                    </>
                                ):(
                                    <>
                                        <ImageGrid item={item} />
                                        <DescriptionGrid item={item} idx={key} />
                                    </>
                                )}
                        </Grid>
                    )
                })}
                <div className="button-content">
                    <Link to={routes.calculateMediaPlan.to} title="Посчитать медиаплан">
                        Посчитать медиаплан
                    </Link>
                </div>
            </Container>
        </section>
    )
}

export default WorkInfo;
