import {BaseProps} from "../index.d";
import {Option} from "generic/editMediaPlan/extra/index.d";
import {IAdvertisingCatalog} from "src/components/advertisingCatalog/index.d";
import {ISetting} from "store/models/index.d";
const _ = require('lodash');

export interface ICatalogItem extends BaseProps {
    setting: ISetting,
    catalogs: IAdvertisingCatalog,
    homeLoading: boolean,
    extraData: {
        catalogSale: boolean
    },
    fnSetFilterValues: any,
    fnFilterApply: any,
    fnSaveMediaPlan: any,
    fnResetFilterValues: any
}

export interface State {
    catalog: TCatalog,
    loading: boolean,
    images: string[],
    filterData: TFilterData,
    filterValues: TFilterValues,
    dialog: boolean,
    dialogMessage: {
        open: boolean,
        title: string,
        message: string
    }
}

export type TFilterData = {
    channels: number[],
    cities: Option[],
    defaultCity: number
}

export type TFilterValues = {
    cities: number[],
    channels: number[],
}

export type TCatalog = {
    color: string,
    crmId: number,
    description: string,
    image: string | null,
    name: string,
    title: string,
    url: string
}

export {
    Option
}

export const defTCatalog = {
    color: "",
    crmId: 0,
    description: "",
    image: null,
    name: "",
    title: "",
    url: ""
}

export const getCatalog = (url: string, catalogs: TCatalog[]) => {
    const key: number = _.findIndex(catalogs, (c: TCatalog) => {
        return c.url === url
    })
    if (key !== -1) {
        return catalogs[key]
    }
    return null
}

export const getCityName = (filterData: TFilterData, filterValues: TFilterValues) => {
    let name: string = "";
    if (filterValues.cities.length > 0) {
        const key: number = _.findIndex(filterData.cities, (o: Option) => {
            return o.value === filterValues.cities[0]
        })
        if (key !== -1) {
            name = filterData.cities[key].name
        }
    }
    return name;
}
export const getCityOption = (filterData: TFilterData, name: string) => {
    let value: Option = {value: 0, name: ""};
    if (name.length > 0) {
        const key: number = _.findIndex(filterData.cities, (o: Option) => {
            return o.value === name
        })
        if (key !== -1) {
            value = filterData.cities[key]
        }
    }
    return value;
}
