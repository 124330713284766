import React, {Component} from "react";
import {connect} from "react-redux";
import {withResizeDetector} from 'react-resize-detector';
import {CircularProgress} from "@material-ui/core";
// import scrollbarSize from 'dom-helpers/scrollbarSize';

import {ITable, getColumns, getColumnWidth, cetFixedColumnWidth} from "./index.d";
import HeaderCell from './extra/headerCell';
import RowCell from './extra/rowCell';
import FilterDialog from './extra/dialog';
import Total from './extra/total';

const ROW_HEIGTH = 50;

type State = {
    dialog: boolean,
    selectedColumn: any,
}

class Table extends Component<ITable, State> {

    readonly state = {
        dialog: false,
        selectedColumn: {
            caption: "",
            filterName: "",
            options: []
        },
    }

    headerOnClick = (selectedColumn: any) => {
        if (selectedColumn?.options?.length) {
            this.setState({
                dialog: true,
                selectedColumn
            })
        }
    }

    handlerDialog = (dialog: boolean) => this.setState({dialog});

    handleApply = (checkeds: any) => {
        const {selectedColumn} = this.state;
        this.props.fnAdditionalFilterApply({
            field: selectedColumn.filterName,
            values: checkeds
        })
        this.setState({dialog: false});
    }

    render = () => {
        const self = this,
            {dialog, selectedColumn} = self.state,
            {width, loading, filterApply, table, additionalFilterValues, handleTableDialog} = self.props,
            columns = getColumns(table.columns),
            fixedColumnWidth = cetFixedColumnWidth(columns);

        let defaultValue = 140, defaultWidth = 0;
        if (!isNaN(width) && width) {
            defaultWidth = width;
            if (width > 1400) {
                defaultValue = (width - fixedColumnWidth.width - fixedColumnWidth.maxWidth) / (columns.length - fixedColumnWidth.count - fixedColumnWidth.maxCount)
            } else {
                defaultValue = (width - fixedColumnWidth.width) / (columns.length - fixedColumnWidth.count)
            }
        };
        return (
            <div id="media-plan-table">
                <div>
                    {!filterApply?null:(
                        <>
                            {loading?(
                                <div className="loading">
                                    <CircularProgress />
                                </div>
                            ):null}
                            <HeaderCell
                                columns={columns}
                                getColumnWidth={getColumnWidth}
                                additionalFilterValues={additionalFilterValues}
                                headerOnClick={self.headerOnClick}
                                rowHeight={ROW_HEIGTH}
                                width={defaultWidth}
                                defaultValue={defaultValue}/>
                            <RowCell
                                data={table.data}
                                columns={columns}
                                getColumnWidth={getColumnWidth}
                                rowHeight={ROW_HEIGTH}
                                width={defaultWidth}
                                defaultValue={defaultValue}/>
                            <Total handleTableDialog={handleTableDialog} />
                        </>
                    )}
                </div>
                {dialog?(
                    <FilterDialog
                        dialog={dialog}
                        dataSource={additionalFilterValues}
                        handlerDialog={self.handlerDialog}
                        handleApply={self.handleApply}
                        selectedColumn={selectedColumn} />
                ):null}
            </div>
        )
    }
}


const mapState = (state: any) => {
    return {
        loading: state.calculateMediaPlan.loading,
        filterApply: state.calculateMediaPlan.filterApply,
        table: state.calculateMediaPlan.table,
        additionalFilterValues: state.calculateMediaPlan.additionalFilterValues,
    };
},
mapDispatch = (dispatch: any) => {
    return {
        fnAdditionalFilterApply: (data: any) => dispatch.calculateMediaPlan.additionalFilterApply(data),
    };
}
export default connect(mapState, mapDispatch)(withResizeDetector(Table));
