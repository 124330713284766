import React from 'react';
import {Provider} from 'react-redux';
import {ToastContainer} from 'react-toastify';
import store from './store';
import Router from './router';

import {ThemeProvider} from '@material-ui/styles';
import {createMuiTheme} from '@material-ui/core/styles';
import {ConfirmProvider} from 'material-ui-confirm';

import Authorization from "components/authorization";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: 'rgb(76, 175, 80)',
        },
    },
});

function App() {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <ConfirmProvider>
                    <Router />
                    <ToastContainer />
                    <Authorization />
                </ConfirmProvider>
            </ThemeProvider>
        </Provider>
    );
}

export default App;
