import React, {Component} from "react";
import {Grid, GridSize, TextField} from '@material-ui/core';
import {IResponse} from '../../../store/fetchUp';

type TFormField = {
    label: string,
    name: string,
    span: any,
}

type TFormValues = {
    [index: string]: string,
    name: string,
    email: string,
    message: string
}

interface IFromState {
    formValues: TFormValues,
    sending: boolean
}

interface IFromProps {
    fnSenfFeedback: any,
    dialogShow: any,
    title: string,
    titleSpan: number
}

const formField: TFormField[] = [
    {name: "name", label: "Ваше имя", span: 6},
    {name: "email", label: "E-mail", span: 6},
    {name: "message", label: "Сообщение", span: 12},
];

const setValue = (val: any): string => {
    if (val) return val.toString();
    return "";
}

const DEF_FORM_VALUES = {
    name: "",
    email: "",
    message: ""
}

class Form extends Component<IFromProps, IFromState> {

    readonly state = {
        formValues: DEF_FORM_VALUES,
        sending: false
    }

    private handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();

        const self = this,
            {formValues} = self.state,
            {fnSenfFeedback, dialogShow} = self.props;

        fnSenfFeedback(formValues, (response: IResponse) => {
            const {status, data} = response;
            if (status === 200) {
                if (data.message) dialogShow(data.message);
                self.setState({formValues: DEF_FORM_VALUES, sending: false});
            } else self.setState({sending: false});
        });
    };

    private handleInput = async (name: string, e: any): Promise<void> => {
        if (e.target) {
            let formValues: TFormValues = Object.assign({}, this.state.formValues);
            formValues[name] = e.target.value;

            this.setState({formValues})
        }
    };

    render = () => {
        const self = this,
            sending: boolean = self.state.sending,
            formValues: TFormValues = self.state.formValues,
            textFieldProps = {
                required: true,
                fullWidth: true
            }
        return (
            <form onSubmit={self.handleSubmit}>
                <Grid
                    container
                    alignItems="center">
                    <Grid item xs={this.props.titleSpan as GridSize}>
                        <div className="welcome-text">
                            {this.props.title}
                        </div>
                    </Grid>
                </Grid>
                {formField.map((field: TFormField, idx: number) => (
                    <Grid
                        key={idx}
                        container
                        alignItems="center">
                        <Grid item xs={field.span}>
                            <div className="form-content">
                                <TextField
                                    name={field.name}
                                    label={field.label}
                                    disabled={sending}
                                    value={setValue(formValues[field.name])}
                                    onChange={(e: any) => self.handleInput(field.name, e)}
                                    {...textFieldProps}/>
                            </div>
                        </Grid>
                    </Grid>
                ))}
                <div className="grid-recaptcha" />
                <button type="submit">
                    Отправить
                </button>
            </form>
        )
    }
}

export default Form;
