import React from "react";
import {Grid, FormControlLabel} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';

import {PropsAuthorization} from "../index.d";
import ChangeGrid from './changeGrid';
import Form from "./form";

const RegistrationForm = (props: PropsAuthorization) => {
    const [checked, setChecked] = React.useState(true),
        {authorization} = props,
        {forms} = authorization,
        handleSubmit = (e: any) => {
            e.preventDefault();
            if (checked) props.fnRegistered()
        },
        handleChange = (event: any) => setChecked(event.target.checked);

    return (
        <div>
            <ChangeGrid
                fnAuthDialog={props.fnAuthDialog}
                formType={authorization.dialog.formType}/>
            <Form
                onSubmit={handleSubmit}
                valueChange={(name: string, value: string) => props.fnFormValueChange({form: 'registration', name, value})}
                fields={forms.registration}>
                <Grid
                    container alignItems="center" className="form-control">
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked}
                                    onChange={handleChange}
                                    color="primary"/>
                            }
                            label={<span className="label-checkbox">Согласен с обработкой персональных данных</span>}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <button
                            disabled={!checked}
                            type="submit">Зарегистрироваться</button>
                    </Grid>
                </Grid>
            </Form>
        </div>
    )
}

export default RegistrationForm;
