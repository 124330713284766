import {IUser} from "./index.d";
import {postAuth, IResponse} from '../fetchUp';
import DB from '../DB';

const initialState: IUser = {
    token: "",
    name: "",
    surname: "",
    company: "",
    phone: "",
    email: "",
    loading: true,
    mediaPlans: {
        items: [],
        totalPage: 0,
        currentPage: 0,
    }
}

const user = {
	state: initialState,
	reducers: {
		update(state: any, payload: any) {
            return {...state, ...payload}
        },
    },
    effects: (dispatch: any) => ({
        init(payload: any, rootState: any) {
            DB._retrieveData("user_token", (jsonstr: string, status: boolean) => {
                if (status) {
                    try {
                        let json: any = JSON.parse(jsonstr);
                        if (json?.token) {
                            postAuth(json.token, "cabinet/get", {}, (response: IResponse, error: boolean) => {
                                if (!error) dispatch.user.set(response.data);
                                else dispatch.user.delete();
                            })
                        }
                    } catch (e) {}
                }
            });
        },
        set(payload: any, rootState: any) {
            const userState = Object.assign(initialState, payload, {loading: false});
            DB._storeData("user_token", JSON.stringify({token: userState.token}), (status: boolean) => {
                if (status) dispatch.user.update(userState);
            })
        },
        delete(payload: any, rootState: any) {
            DB._storeData("user_token", JSON.stringify({token: ""}), (status: boolean) => {
                if (status) dispatch.user.update(initialState);
            })
        },
        save(payload: any, rootState: any) {
            const {formValues, callback} = payload;
            postAuth(rootState.user.token, "cabinet/update", formValues, (response: IResponse, error: boolean) => {
                if (!error) dispatch.user.update(response.data);
                if (callback) callback(error)
            })
        },
        logout(payload: any, rootState: any) {
            postAuth(rootState.user.token, "cabinet/logout", {}, () => {
                dispatch.user.delete();
            })
        },
        getMediaPlans(payload: any, rootState: any) {
            const {data, callback} = payload;
            postAuth(rootState.user.token, "cabinet/get-media-plans", data, (response: IResponse, error: boolean) => {
                if (!error) dispatch.user.set({mediaPlans: response.data});
                if (callback) callback(error)
            })
        },
        deleteMediaPlans(payload: any, rootState: any) {
            const {id, callback} = payload;
            postAuth(rootState.user.token, "mediaplan/delete", {id}, (response: IResponse, error: boolean) => {
                console.log('response', response);

                if (callback) callback(error)
            })
        },
        downloadMediaPlans(payload: any, rootState: any) {
            const {id, callback} = payload;
            postAuth(rootState.user.token, "mediaplan/download", {id}, (response: IResponse, error: boolean) => {
                if (!error && response.data) {
                    const {redirect} = response.data;
                    if (redirect) window.open(redirect);
                }
                if (callback) callback(error)
            })
        },
        getMediaPlan(payload: any, rootState: any) {
            const {id, callback} = payload;
            DB._retrieveData("user_token", (jsonstr: string, status: boolean) => {
                if (status) {
                    try {
                        let json: any = JSON.parse(jsonstr);
                        if (json?.token) {
                            postAuth(json.token, "mediaplan/get", {id}, (response: IResponse, error: boolean) => {
                                if (callback) callback(response, error)
                            })
                        } else if (callback) callback("Ошибка")
                    } catch (e) {
                        if (callback) callback("Ошибка")
                    }
                }
            });
        },
    })
}

export default user
