import React, {Component} from "react";
import {connect} from "react-redux";
import ReactResizeDetector from 'react-resize-detector';
import {Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField, CircularProgress} from "@material-ui/core";
import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';
import PrintIcon from '@material-ui/icons/Print';
import GetAppIcon from '@material-ui/icons/GetApp';
import SaveIcon from '@material-ui/icons/Save';
import {NavLink} from "react-router-dom";

import DataPicker from "generic/editMediaPlan/extra/dataPicker";

import {
    ICalculateMediaPlan_ChangesData as IChangesData,
    ICalculateMediaPlan_ChangeData,
    ICalculateMediaPlan_Items, IUser,
    ICalculateMediaPlan_FilterValues as IFilterValues
} from "store/models/index.d";

import {getColumns} from "./index.d";
import {getColumnWidth, cetFixedColumnWidth} from "components/table-virtualized/index.d";
import HeaderCell from 'components/table-virtualized/extra/headerCell';
import RowCell from 'components/table-virtualized/extra/rowCell';

type Props = {
    saved: boolean,
    open: boolean,
    user: IUser,
    handlerDialog: any,
    filterValues: any,
    selected: IChangesData,
    additionalFilterValues: any,
    fnSaveMediaPlan: any,
    fnSetFilterValues: any,
    fnAuthDialog: any,
}

const ROW_HEIGTH = 50;

class TableDialog extends Component<Props> {

    static defaultProps = {
        saved: false,
        open: false,
        handlerDialog: () => {},
        filterValues: {},
        selected: [],
        fnSetFilterValues: () => {},
        fnAuthDialog: () => {},
    }

    getSelectedData = (): ICalculateMediaPlan_ChangeData[] => {
        const {selected} = this.props;
        let data: ICalculateMediaPlan_ChangeData[] = [];
        Object.keys(selected).forEach((id: string) => {
            const value: ICalculateMediaPlan_ChangeData = selected[Number(id)];
            if (value.selected)
                data.push(value);
        });
        return data;
    }

    getDataBySelected = (): ICalculateMediaPlan_Items[] => {
        const {selected} = this.props;
        let data: ICalculateMediaPlan_Items[] = [];
        Object.keys(selected).forEach((id: string) => {
            const value: ICalculateMediaPlan_ChangeData = selected[Number(id)];
            if (value.selected)
                data.push(value.data);
        });
        return data;
    }

    getTotalSum = (): string => {
        let totalPrace: number = 0;

        this.getSelectedData().forEach((data: ICalculateMediaPlan_ChangeData) => {
            totalPrace += data.price;
        });

        return totalPrace.toLocaleString('ru-RU');
    }

    setFilterName = (e: any) => {
        const {filterValues} = this.props;
        let value: string = e?.target?.value,
        fields: IFilterValues = Object.assign({}, filterValues);

        fields.name = value;
        this.props.fnSetFilterValues({...fields});
    }

    setFilterRangeDate = (range: any) => {
        const {filterValues} = this.props;
        let fields: IFilterValues = Object.assign({}, filterValues);
        fields.date = range;

        this.props.fnSetFilterValues({...fields});
    }

    saveOrRequest = (type: string): void => {
        const self = this,
            {user, handlerDialog, fnSaveMediaPlan, fnAuthDialog} = self.props;

        if (user.token) fnSaveMediaPlan(type)
        else {
            handlerDialog(false)
            fnAuthDialog({show: true})
        }
    }

    onClose = (): void => {
        const {saved, handlerDialog} = this.props;
        if (saved && handlerDialog) handlerDialog(false)
    }

    render = () => {
        const self = this,
            {saved, open, filterValues, handlerDialog, fnSaveMediaPlan} = self.props,
            columns = getColumns(),
            fixedColumnWidth = cetFixedColumnWidth(columns);
        return (
            <Dialog
                fullWidth={true}
                className="table-dialog"
                onClose={self.onClose}
                open={open}>
                <DialogTitle>
                    <Grid
                        className="input-grid"
                        container
                        spacing={5}>
                        <Grid item xs={8}>
                            <TextField
                                label="Название медиаплана"
                                value={filterValues.name}
                                onChange={(e: any) => self.setFilterName(e)}
                                fullWidth />
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={3}>
                            <DataPicker
                                defaultValue={filterValues.date}
                                setFilterRangeDate={self.setFilterRangeDate}/>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <ReactResizeDetector handleWidth>
                    {({width}: {width: number}) => {
                        let defaultValue = 140, defaultWidth = 1000;
                        if (!isNaN(width)) {
                            defaultWidth = width;
                            if (width > 1400) {
                                defaultValue = (width - fixedColumnWidth.width - fixedColumnWidth.maxWidth) / (columns.length - fixedColumnWidth.count - fixedColumnWidth.maxCount)
                            } else {
                                defaultValue = (width - fixedColumnWidth.width) / (columns.length - fixedColumnWidth.count)
                            }
                            return (
                                <div>
                                    <HeaderCell
                                        columns={columns}
                                        getColumnWidth={getColumnWidth}
                                        additionalFilterValues={self.props.additionalFilterValues}
                                        headerOnClick={() => {}}
                                        rowHeight={ROW_HEIGTH}
                                        width={defaultWidth}
                                        defaultValue={defaultValue}/>
                                    <RowCell
                                        data={this.getDataBySelected()}
                                        columns={columns}
                                        getColumnWidth={getColumnWidth}
                                        rowHeight={ROW_HEIGTH}
                                        width={defaultWidth}
                                        defaultValue={defaultValue}/>
                                </div>
                            );
                        }
                        return (
                            <div className="loading">
                                <CircularProgress />
                            </div>
                        )
                    }}
                </ReactResizeDetector>
                <DialogContent>
                    <Grid container className="DialogContent-footer">
                        <Grid item xs={6}>
                            <button
                                onClick={()=> handlerDialog(false)}
                                className="undo-button">
                                <UndoIcon /> {saved?"Назад":"Редактировать"}
                            </button>
                            {(saved && filterValues?.id)?(
                                <NavLink to={`/cabinet/media-plan/${filterValues.id}`}
                                    className="redo-button">
                                    Редактировать <RedoIcon />
                                </NavLink>
                            ):null}
                        </Grid>
                        <Grid item xs={6} className="final-budget">
                            Итоговый бюджет: <span>{self.getTotalSum()}</span>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container className="DialogContent-footer">
                        <Grid item xs={8}>
                            <button onClick={() => fnSaveMediaPlan("print")}>
                                <PrintIcon /> Распечатать
                            </button>
                            <button onClick={() => fnSaveMediaPlan("excel")}>
                                <GetAppIcon /> Скачать Excel
                            </button>
                            <button onClick={() => self.saveOrRequest("save")}>
                                <SaveIcon /> Сохранить в ЛК
                            </button>
                        </Grid>
                        <Grid item xs={4} className="final-budget">
                            <button onClick={() => self.saveOrRequest("request")}>
                                Отправить заявку
                            </button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapState = (state: any) => {
    return {
        user: state.user,
        filterValues: state.calculateMediaPlan.filterValues,
        selected: state.calculateMediaPlan.changesData,
        additionalFilterValues: state.calculateMediaPlan.additionalFilterValues,
    };
},
mapDispatch = (dispatch: any) => {
    return {
        fnSetFilterValues: (formValues: any) => dispatch.calculateMediaPlan.setFilterValues(formValues),
        fnAuthDialog: (data: any) => dispatch.authorization.dialog(data),
    };
}

export default connect(mapState, mapDispatch)(TableDialog);
