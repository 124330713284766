import {post, IResponse} from '../fetchUp';
import {IAuthorization, IAuthItemForm, EnumAuthForm} from "./index.d";
const _ = require('lodash');

const initialState: IAuthorization = {
    dialog: {
        show: false,
        formType: EnumAuthForm.Login
    },
    forms: {
        login: [
            {name: "email", label: "Email", value: "", type: "text"},
            {name: "password", label: "Пароль", value: "", type: "password"},
        ],
        registration: [
            {name: "name", label: "Имя", value: "", type: "text"},
            {name: "surname", label: "Фамилия", value: "", type: "text"},
            {name: "email", label: "Email", value: "", type: "text"},
            {name: "phone", label: "Телефон", value: "", type: "text"},
            {name: "company", label: "Компания", value: "", type: "text"},
            {name: "password", label: "Пароль", value: "", type: "password"},
            {name: "password_confirmation", label: "Подтвердите пароль", value: "", type: "password"},
        ]
    }
}

const authResponse = (url: string, dataSource: IAuthItemForm[], callback: any) => {
    const formValue: any = {};
    dataSource.forEach((val: IAuthItemForm) => {
        formValue[val.name] = val.value
    });

    post(url, formValue, (response: IResponse, error: boolean) => {
        if(!error && callback) callback(response.data)
    })
}

const authorization = {
	state: initialState,
	reducers: {
		stateUpdate(state: any, payload: any) {
            return {...state, ...payload}
        },
    },
    effects: (dispatch: any) => ({
        dialog(payload: any, rootState: any) {
            const state = Object.assign(rootState.authorization.dialog, payload)
            dispatch.authorization.stateUpdate({dialog: state})
        },
        formValueChange(payload: any, rootState: any) {
            const forms = Object.assign({}, rootState.authorization.forms)
            if (forms[payload.form]) {
                const key =  _.findIndex(forms[payload.form], function(o: IAuthItemForm) { return o.name === payload.name})
                if (key !== -1) {
                    forms[payload.form][key].value = payload.value
                    dispatch.authorization.stateUpdate({forms})
                }
            }
        },
        registered(payload: any, rootState: any) {
            const {forms} = rootState.authorization;
            authResponse('registered', forms.registration, (response: any) => {
                dispatch.user.set(response)
                dispatch.authorization.dialog({show: false})
            })
        },
        login(payload: any, rootState: any) {
            const {forms} = rootState.authorization;
            authResponse('login', forms.login, (response: any) => {
                dispatch.user.set(response)
                dispatch.authorization.dialog({show: false})
            })
        }
    })
}

export default authorization
