import React from "react";
import {connect} from "react-redux";
import {CircularProgress} from "@material-ui/core";
import {GeneralEditMediaPlan, GeneralProps, GeneralState} from "generic/editMediaPlan";
import {ICalculateMediaPlan_FilterValues} from "store/models/index.d";
import {IResponse} from 'store/fetchUp';

interface Props extends GeneralProps {
    filterValues: ICalculateMediaPlan_FilterValues
    fnFilterApply: (callback: any) => any,
    fnResetMediaPlan: () => any,
    fnSetMediaPlan: (data: any, callback: any) => void,
    fnGetMediaPlan: (id: any, callback: any) => void
}

interface State extends GeneralState {
    loading: boolean,
}

class EditMediaPlan extends GeneralEditMediaPlan<Props, State> {

    readonly state = {
        loading: true,
        // from Generals
        dialog: false,
        dialogMessage: {
            open: false,
            title: "",
            message: "",
        }
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        
        const self = this,
        {params, fnResetMediaPlan, fnGetMediaPlan, fnSetMediaPlan, fnFilterApply} = self.props;

        if (params?.id) {
            fnResetMediaPlan().then(() => {
                fnGetMediaPlan(params.id, (response: IResponse, error: boolean) => {
                    if (!error && response.status === 200) {
                        fnSetMediaPlan(response.data, () => {
                            self.setState({loading: false}, () => {
                                if (fnFilterApply) fnFilterApply(() => {});
                            });
                        })
                    } // else Error
                })
            })
        } // else Error
    }

    render = () => {
        const {loading} = this.state,
        {filterValues} = this.props;

        if (loading) return (
            <div className="loading editMediaPlan-content">
                <CircularProgress />
            </div>
        )
        let title = "Редактирование медиаплана ";
        if (filterValues?.name) title += `"${filterValues.name}"`;
        return this.renderContent(title, true)
    }
}

const mapState = (state: any) => {
    return {
        setting: state.setting,
        filterValues: state.calculateMediaPlan.filterValues
    };
}, mapDispatch = (dispatch: any) => {
    return {
        fnResetMediaPlan: () => dispatch.calculateMediaPlan.resetFilterValues({}),
        fnSetMediaPlan: (data: any, callback: any) => dispatch.calculateMediaPlan.setMediaPlan({data, callback}),
        fnGetMediaPlan: (id: any, callback: any) => dispatch.user.getMediaPlan({id, callback}),
        fnFilterApply: (callback: any) => dispatch.calculateMediaPlan.filterApply({callback}),
        // from Generals
        fnSaveMediaPlan: (type: any, callback: any) => dispatch.calculateMediaPlan.save({type, callback}),
    };
}

export default connect(mapState, mapDispatch)(EditMediaPlan);
