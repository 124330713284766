import React from "react";
import {NavLink} from "react-router-dom";
import {TCatalog} from "../index.d";

type Props = {
    catalogs: TCatalog[]
}

const Navigation: React.FC<Props> = ({catalogs}) => {
    return (
        <div className="navigation-grid">
            <ul>
                {catalogs.map((item: TCatalog, idx) => {
                    return (
                        <li key={idx}>
                            <NavLink to={`/catalog/${item.url}`} activeClassName="selected">
                                {item.name}
                            </NavLink>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default Navigation;
