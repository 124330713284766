import React, {useState} from "react";
import {Dialog, DialogTitle, DialogContent, DialogActions,
    Button, ListItem, ListItemIcon, Checkbox, ListItemText} from "@material-ui/core";
import {ICalculateMediaPlan_AdditionalFilterValues} from "store/models/index.d";
import {AutoSizer, List} from "react-virtualized";

const _ = require('lodash');

type Props = {
    dataSource: ICalculateMediaPlan_AdditionalFilterValues
    dialog: boolean,
    selectedColumn: any,
    handlerDialog: any,
    handleApply: any,
}

type TRowRenderer = {
    checkeds: any,
    options: any,
    handleSelected: any,
    index: number,
    isScrolling: any
    key: any
    style: any
}

const _rowRenderer: React.FC<TRowRenderer> =
({options, handleSelected, checkeds, index, isScrolling, key, style}) => {

    const val = options[index];
    const labelId = `checkbox-list-label-${index}`;
    let checked = false;
    if (_.isArray(val.id)) {
        if (checkeds.some((v: any) => v.join() === val.id.join())) checked = true;
    } else checked = _.includes(checkeds, val.id);

    return (
        <div key={key} style={style}>
            <ListItem dense button onClick={() => handleSelected(!checked, val.id)}>
                <ListItemIcon>
                    <Checkbox
                        edge="start"
                        checked={checked}
                        onChange={(e: any) => handleSelected(!checked, val.id)}
                        tabIndex={-1}
                        disableRipple
                        color="primary"
                        inputProps={{
                            'aria-labelledby': labelId
                        }}/>
                </ListItemIcon>
                <ListItemText id={labelId} primary={val.name} />
            </ListItem>
        </div>
    )
}

const FilterDialog: React.FC<Props> = ({dataSource, dialog, selectedColumn, handlerDialog, handleApply}) => {
    const stateData = dataSource[selectedColumn.filterName.toLowerCase()]?dataSource[selectedColumn.filterName.toLowerCase()]:[],
    [checkeds, setChecked] = useState(stateData),
    handleSelected = (checked: boolean, id: any) => {
        let data: any = [];
        if (checked) {
            data = Object.assign([], checkeds);
            data.push(id);
        } else {
            checkeds.forEach((element: any) => {
                if (Array.isArray(element) && Array.isArray(id)) {
                    if (element.join() !== id.join()) data.push(element)
                } else if (element !== id) data.push(element)
            });
        }
        setChecked(data);
    }

    const contentHeight: number = selectedColumn.options.length * 50;

    return (
        <Dialog
            onClose={() => handlerDialog(false)}
            open={dialog}
            className="dialog-filter">
            <DialogTitle>
                {selectedColumn.caption}
            </DialogTitle>
            <DialogContent>
                <AutoSizer disableHeight>
                    {({width}) => (
                        <List
                            height={contentHeight > 500?500:contentHeight}
                            overscanRowCount={5}
                            rowCount={selectedColumn.options.length}
                            rowHeight={50}
                            rowRenderer={(data: any) => _rowRenderer({...data, checkeds, handleSelected, options: selectedColumn.options})}
                            width={width}/>
                    )}
                </AutoSizer>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => handlerDialog(false)}
                    color="secondary">
                    Закрыть
                </Button>
                <Button
                    onClick={() => handleApply(checkeds)}
                    color="primary">
                    Применить
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default FilterDialog;
