import React from "react";
import {Link} from "react-router-dom";
import {Container} from '@material-ui/core';
import {CarouselProvider, Slider, Slide, ButtonBack, ButtonNext} from 'pure-react-carousel';
import ReactResizeDetector from 'react-resize-detector';
import routes from "../../router/routes";

interface IReview {
    image: string,
    name: string,
}

interface IReviews {
    title: string,
    items: IReview[]
}

const Reviews = ({title, items}: IReviews) => {
    return (
        <section id="reviews">
            <div className="title">{title}</div>
            <Container fixed>
                <div className="grid-card">
                    <ReactResizeDetector handleWidth>
                        {({width, height}: {width: number, height: number}) => {
                            return (
                                <CarouselProvider
                                    visibleSlides={3}
                                    infinite={true}
                                    isIntrinsicHeight={true}
                                    naturalSlideWidth={342}
                                    naturalSlideHeight={468}
                                    totalSlides={items.length}>
                                    <Slider>
                                        {items.map((item: IReview, key: number) => {
                                            return (
                                                <Slide index={key} key={key}>
                                                    <div className="card" style={{height: height}}>
                                                        <div>
                                                            <div className="image">
                                                                <img src={item.image} alt={item.name} />
                                                            </div>
                                                            <div className="description">
                                                                {item.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Slide>
                                            )
                                        })}
                                    </Slider>
                                    <ButtonBack className="button-back">
                                        <span></span>
                                    </ButtonBack>
                                    <ButtonNext className="button-next">
                                        <span></span>
                                    </ButtonNext>
                                </CarouselProvider>
                            )
                        }}
                    </ReactResizeDetector>
                </div>
                <div className="button-content">
                    <Link to={routes.calculateMediaPlan.to} title="Посчитать медиаплан">
                        Посчитать медиаплан
                    </Link>
                </div>
            </Container>
        </section>
    )
}

export default Reviews;
