import {init} from '@rematch/core'
import * as models from './models'

const store = init({
    models,
})

store.dispatch.setting.init({});
store.dispatch.home.init({});
store.dispatch.user.init({});
export default store
