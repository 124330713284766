import React, {Component} from 'react';
import {Container, Grid} from '@material-ui/core';
import {connect} from "react-redux";
import Skeleton from 'react-loading-skeleton';

import {ICabinet} from "./index.d"
import Form from "./extra/form"
import MediaPlans from "./extra/mediaPlans"

class Cabinet extends Component<ICabinet> {

    // componentDidMount = () => {
    //     const {history, user} = this.props;
    //     if (user.loading) history.replace("/");
    // }

    componentDidMount = () => {
        window.scrollTo(0, 0);
    }

    render = () => {
        const self = this,
            {user} = this.props;
        return (
            <div id="cabinet">
                <Container fixed>
                    <div className="title">
                        {!user.loading?`Здравствуйте, ${user.name}!`:<Skeleton height={40} width={300} />}
                    </div>
                    <Grid
                        container
                        spacing={3}>
                        <Grid item xs={5}>
                            <div className="grid-item">
                                <div className="grid-title">Мои данные</div>
                                <div className={`form-content ${!user.loading?"enable":""}`}>
                                    {user.loading?<Skeleton height={62} count={5} />:(
                                        <Form
                                            {...self.props}/>
                                    )}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={7}>
                            <div className="grid-item media-plans">
                                <div className="grid-title">Мои медиапланы</div>
                                <div className="media-plans-content">
                                    {user.loading?<Skeleton height={323} />:(
                                        <MediaPlans
                                            {...self.props}/>
                                    )}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        )
    }
}

const mapState = (state: any) => {
    return {
        user: state.user,
    };
},
mapDispatch = (dispatch: any) => {
    return {
        fnSave: (formValues: any, callback: any) => dispatch.user.save({formValues, callback}),
        fnGetMediaPlans: (data: any, callback: any) => dispatch.user.getMediaPlans({data, callback}),
        fnDeleteMediaPlans: (id: any, callback: any) => dispatch.user.deleteMediaPlans({id, callback}),
        fnDownloadMediaPlans: (id: any, callback: any) => dispatch.user.downloadMediaPlans({id, callback}),
    };
}

export default connect(mapState, mapDispatch)(Cabinet);
