import React from 'react';
import {useHistory, useParams} from "react-router-dom";

interface IRouterItem {
    component: any,
    extraData?: any
}

const RouterItem: React.FC<IRouterItem> = ({component, extraData}) => {
    const params = useParams(),
        history = useHistory(),
        extra = extraData?extraData:{},
        Component = component;

    return <Component {...{params, history, extraData: extra}} />
}

export default RouterItem;
