import React from 'react';
import {connect} from "react-redux";
import {Dialog, DialogContent} from '@material-ui/core';

import {EnumAuthForm} from "../../store/models/index.d";
import EnterForm from "./extra/enter";
import RegistrationForm from "./extra/registration";
import {PropsAuthorization} from "./index.d";

const Authorization = (props: PropsAuthorization) => {
    const {authorization, fnAuthDialog} = props,
        {dialog} = authorization;

    return (
        <Dialog
            className="dialog-authorization"
            open={dialog.show}
            keepMounted
            maxWidth="lg"
            onClose={() => fnAuthDialog({show: false})}>
            <DialogContent>
            <div className="dialog-content">
                <div>
                    {dialog.formType === EnumAuthForm.Login?(
                        <EnterForm {...props} />
                    ):<RegistrationForm {...props} />}
                </div>
            </div>
            </DialogContent>
        </Dialog>
    )
}

const mapState = (state: any) => {
    return {
        authorization: state.authorization,
    };
},
mapDispatch = (dispatch: any) => {
    return {
        fnAuthDialog: (data: any) => dispatch.authorization.dialog(data),
        fnFormValueChange: (data: any) => dispatch.authorization.formValueChange(data),
        fnRegistered: (data: any) => dispatch.authorization.registered(data),
        fnLogin: (data: any) => dispatch.authorization.login(data),
    };
}

export default connect(mapState, mapDispatch)(Authorization);
