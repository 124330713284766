import React from "react";
import {connect} from "react-redux";
import {Container, Grid} from '@material-ui/core';
import {YMaps, Map, RouteButton, TrafficControl, ZoomControl, ObjectManager} from "react-yandex-maps";

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import {BaseProps} from "../index.d";
import {ISetting} from "../../store/models/index.d";
import FormHeader from "./extra/formHeader";
import Form from "./extra/form";

interface IContacts extends BaseProps {
    setting: ISetting,
    fnSenfFeedback: any,
    disabledBackground?: boolean,
    disabledHeader?: boolean,
    formTitle?: string,
    formTitleSpan?: number,
    visibleMap?: boolean,
}

const Contacts: React.FC<IContacts> = ({
    setting, fnSenfFeedback,
    disabledBackground = false, disabledHeader = false,
    formTitle = "Для заявок и предложений",
    formTitleSpan = 6,
    visibleMap = true
}) => {
    const [dialog, setDialog] = React.useState({show: false, message: ""}),
        handleShow = (message: string) => {
            setDialog({show: true, message: message})
        },
        handleClose = () => setDialog({...dialog, show: false})

    React.useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <>
        <div id="contacts" style={disabledBackground ? {background: "transparent"} : {}}>
            <Container fixed>
                <Grid
                    className="grid-container"
                    container>
                    <Grid item xs={7}>
                        {!disabledHeader && (
                            <FormHeader setting={setting}/>
                        )}
                        <Form
                            title={formTitle}
                            titleSpan={formTitleSpan}
                            dialogShow={handleShow}
                            fnSenfFeedback={fnSenfFeedback}/>
                    </Grid>
                    <Grid item xs={5}>
                        <div className="bg-contacts" />
                    </Grid>
                </Grid>
            </Container>
            <Dialog
                open={dialog.show}
                keepMounted
                onClose={handleClose}>
                    <DialogContent>
                        <div className="dialog-grid">
                            <div className="images-grid">
                                <div />
                            </div>
                            <div className="description-grid">
                                <div>
                                    <div className="title">Спасибо! Ваша заявка успешно отправлена.</div>
                                    <div
                                        className="message"
                                        dangerouslySetInnerHTML={{
                                            __html: dialog.message
                                        }}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
            </Dialog>
        </div>
        {visibleMap && (
            <YMaps>
                <Map
                    options={{
                        wheel: "click"
                    }}
                    style={{width: "100%", height: 400}}
                    defaultState={{ center: [56.831275, 60.603296], zoom: 15 }}>
                    <ObjectManager
                        options={{
                            clusterize: true,
                            gridSize: 32,
                        }}
                        objects={{
                            openBalloonOnClick: true,
                            preset: 'islands#greenDotIcon',
                        }}
                        clusters={{
                            preset: 'islands#redClusterIcons',
                        }}
                        defaultFeatures={{
                            type: "Feature",
                            id: 1,
                            geometry: {
                                type: "Point",
                                coordinates: [56.831275, 60.603296]
                            },
                            options: {
                                iconColor: "#4caf50"
                            },
                            properties: {
                                hintContent: "Екатеринбург, Добролюбова 16",
                                balloonContent: "Екатеринбург, Добролюбова 16"
                            }
                        }}
                        modules={[
                            "objectManager.addon.objectsBalloon",
                            "objectManager.addon.objectsHint",
                        ]}/>
                    <RouteButton options={{ float: "right" }}/>
                    <TrafficControl options={{ float: "right" }}/>
                    <ZoomControl options={{ float: "right" }} />
                </Map>
            </YMaps>
        )}
        </>
    )
}

const mapState = (state: any) => {
    return {
        setting: state.setting,
    };
};

const mapDispatch = (dispatch: any) => {
    return {
        fnSenfFeedback: (formValues: any, callback: any) => dispatch.setting.senfFeedback({formValues, callback}),
    };
}

export default connect(mapState, mapDispatch)(Contacts);