import React, {Component} from "react";
import {connect} from "react-redux";
import {Container, Grid, CircularProgress} from '@material-ui/core';
// import moment from "moment";

import {getApi, post, IResponse} from "store/fetchUp";
import {ICatalogItem, State, TFilterData, TFilterValues, Option, getCatalog, defTCatalog} from "./index.d";

import Description from "./extra/description";
import Navigation from "./extra/navigation";
import Table from "components/table-virtualized";
import TableDialog from "components/table-dialog";
import DialogMessage from "components/dialogMessage";

class CatalogItem extends Component<ICatalogItem, State> {

    readonly state = {
        catalog: defTCatalog,
        images: [],
        loading: true,
        filterData: {
            channels: [],
            cities: [],
            defaultCity: 0,
        },
        filterValues: {
            cities: [],
            channels: [],
        },
        dialog: false,
        dialogMessage: {
            open: false,
            title: "",
            message: "",
        }
    }

    componentDidUpdate = (prevProps: ICatalogItem) => {
        if (prevProps.homeLoading !== this.props.homeLoading) this.getCatalogData()
        else if (prevProps.params?.id !== this.props.params?.id) this.getCatalogData()
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        
        this.getCatalogData()
    }

    getCatalogData = () => {
        const self = this,
            {params, catalogs, fnResetFilterValues} = self.props;

        if (params?.id) {
            fnResetFilterValues().then(() => {
                const catalog = getCatalog(params.id, catalogs.items)
                if (catalog) {
                    self.setState({catalog, loading: true}, () => {
                        post("catalog/image", {url: catalog.url}, (response: IResponse, error: boolean) => {
                            if (!error && response.data) {
                                const images: string[] = response.data;
                                self.setState({images}, () => {
                                    getApi(`api-out/get_catalog/?serviceType=${catalog.crmId}`, {}, (response: IResponse, error: boolean) => {
                                        if (!error && response.status === 200) {
                                            const filterData: TFilterData = response.data;
                                            let filterValues: TFilterValues = {
                                                ...self.state.filterValues,
                                                cities: [filterData.defaultCity],
                                                channels: filterData.channels
                                            }
                                            self.setState({filterData, filterValues}, () => self.applyFilter())
                                        }
                                    })
                                })
                            }
                        })
                    })
                }
            });
        }
        // FIXED: error 404 not found
    }

    applyFilter = () => {
        const self = this,
            {filterValues} = self.state,
            {fnSetFilterValues, fnFilterApply} = self.props;

        fnSetFilterValues(filterValues).then((data: any) => {
            fnFilterApply(() => {
                self.setState({loading: false})
            })
        })
    }

    handlerChangeCity = (option: Option): void => {
        const self = this,
        // {catalog} = this.state,
        filterValues: TFilterValues = Object.assign({}, this.state.filterValues);

        filterValues.cities = [option.value];
        this.setState({filterValues}, () => {
            self.applyFilter();
        })
    }

    handleTableDialog = (type: boolean) => this.setState({dialog: type})

    fnSaveMediaPlan = (type: string) => {
        const self = this;

        self.props.fnSaveMediaPlan(type, (data: any) => {
            self.setState({dialog: false}, () => {
                const {message, title} = data;
                if (message && title) {
                    this.setState({dialogMessage: {
                        open: true,
                        message, title
                    }})
                }
            })
        })
    }

    handleCloseDialogMessage = () => {
        const {dialogMessage} = this.state;
        this.setState({dialogMessage: {
            ...dialogMessage,
            open: false
        }})
    }

    render = () => {
        const self = this,
            {catalog, images, loading, filterData, filterValues, dialogMessage} = self.state,
            {catalogs, setting} = self.props;

        return (
            <div id="catalog-item">
                {loading?(
                    <div className="loading">
                        <CircularProgress />
                    </div>
                ):(
                    <>
                        <Container fixed>
                            <Grid
                                className="grid-title" container
                                justify="center" alignItems="center"
                                spacing={0}>
                                <Grid item xs={6}>
                                    <h1 className="title">
                                        {catalog.name}
                                    </h1>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="link-to-instruction">
                                        {setting.instruction?(
                                            <a href={setting.instruction} title="Инструкция" target="_blank" rel="noopener noreferrer">
                                                Инструкция
                                            </a>
                                        ):null}
                                    </div>
                                </Grid>
                            </Grid>
                        </Container>
                        <Container fixed className="description-grid">
                            <Navigation catalogs={catalogs.items}/>
                            <Description
                                filterData={filterData}
                                filterValues={filterValues}
                                handlerChangeCity={self.handlerChangeCity}
                                images={images}
                                catalog={catalog}/>
                        </Container>
                        <Grid
                            container
                            spacing={0}>
                            <Grid item xs={12}>
                                <Table
                                    handleTableDialog={self.handleTableDialog}/>
                            </Grid>
                        </Grid>
                        <TableDialog
                            open={self.state.dialog}
                            handlerDialog={self.handleTableDialog}
                            fnSaveMediaPlan={self.fnSaveMediaPlan}/>
                        <DialogMessage
                            open={dialogMessage.open}
                            title={dialogMessage.title}
                            message={dialogMessage.message}
                            handleClose={self.handleCloseDialogMessage}/>
                    </>
                )}
            </div>
        )
    }
}

const mapState = (state: any) => {
    return {
        setting: state.setting,
        catalogs: state.home.advertisingCatalog,
        homeLoading: state.home.loading,
    };
}, mapDispatch = (dispatch: any) => {
    return {
        fnResetFilterValues: () => dispatch.calculateMediaPlan.resetFilterValues({}),
        fnSetFilterValues: (formValues: any) => dispatch.calculateMediaPlan.setQuietlyFilterValues(formValues),
        fnFilterApply: (callback: any) => dispatch.calculateMediaPlan.filterApply({callback}),
        fnSaveMediaPlan: (type: any, callback: any) => dispatch.calculateMediaPlan.save({type, callback}),
    };
}
export default connect(mapState, mapDispatch)(CatalogItem);
