import React, {Component} from 'react';
import {Container, Grid} from '@material-ui/core';
import {connect} from "react-redux";
import {BaseProps} from "../index.d";

import AdvertisingCatalog from "../../components/advertisingCatalog";
import {IAdvertisingCatalog} from "../../components/advertisingCatalog/index.d";

interface ICatalogScreen extends BaseProps {
    catalog: IAdvertisingCatalog,
    extraData: {
        catalogSale: boolean
    }
}

const SaleTitle = () => (
    <Container fixed>
        <Grid
            className="grid-title"
            container
            justify="center"
            alignItems="center"
            spacing={0}>
            <Grid item xs={6}>
                <div className="title-left">
                    КАТАЛОГ СПЕЦИАЛЬНЫХ ПРЕДЛЖЕНИЙ
                </div>
            </Grid>
            <Grid item xs={6}>
                <div className="title-right">
                    IN <span>SALE</span>
                </div>
            </Grid>
        </Grid>
    </Container>
)

class Catalog extends Component<ICatalogScreen> {

    componentDidMount = () => {
        window.scrollTo(0, 0);
    }

    render = () => {
        const self = this,
            {catalog, extraData} = self.props;

        if (extraData.catalogSale) {
            return (
                <div id="page-catalog-sale">
                    <SaleTitle />
                    <AdvertisingCatalog
                        {...catalog}
                        title=""/>
                </div>
            )
        }
        return (
            <div id="page-catalog">
                <AdvertisingCatalog
                    {...catalog}/>
            </div>
        )
    }
}

const mapState = (state: any) => {
    return {
        catalog: state.home.advertisingCatalog,
    };
};
export default connect(mapState)(Catalog);
