import React from "react";
import {Container, Grid} from '@material-ui/core';

interface IItemOurAdvantages {
    color: string,
    description: string,
    image: string,
    name: string,
}

const OurAdvantages = ({items}: {items: IItemOurAdvantages[]}) => {
    return (
        <section id="our-advantages">
            <Container fixed>
                <Grid
                    className="grid-container"
                    container
                    spacing={5}>
                    {items.map((item: IItemOurAdvantages, key: number) => {
                        return (
                            <Grid key={key} item xs={4}>
                                <div className="card">
                                    <div className="image">
                                        <div style={{backgroundImage: `url(${item.image})`}} />
                                    </div>
                                    <div className="name" style={{color: item.color}}>
                                        {item.name}
                                    </div>
                                    <div className="description">
                                        {item.description}
                                    </div>
                                </div>
                            </Grid>
                        )
                    })}
                </Grid>
            </Container>
        </section>
    )
}

export default OurAdvantages;
