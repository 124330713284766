import React, {Component} from "react";
import DateRangeIcon from '@material-ui/icons/DateRange';
import {
    InputAdornment, FormControl, Input, InputLabel,
    Dialog, DialogContent, DialogTitle, DialogActions,
    DialogContentText, Button
} from "@material-ui/core";
import DayPicker, {DateUtils} from 'react-day-picker';
import moment from "moment";

interface StateDataPicker {
    dialog: boolean,
    from: any,
    to: any,
    rangeDate: {
        from: string,
        to: string,
    },
}

type Props = {
    setFilterRangeDate: any,
    defaultValue: {
        from: string,
        to: string,
    }
}

const propsPicker = {
    locale: "ru",
    months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    weekdaysLong: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    weekdaysShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    firstDayOfWeek: 1,
    labels: {nextMonth: 'Следующий месяц', previousMonth: 'Предыдущий месяц'},
    numberOfMonths: 2
}

class DataPicker extends Component<Props> {

    readonly state = {
        dialog: false,
        rangeDate: {
            from: "",
            to: "",
        },
        from: undefined,
        to: undefined,
    }

    componentDidMount = () => {
        const {defaultValue} = this.props;
        if (defaultValue) {
            let date: any = this.getInitialState()
            let rangeDate: any = {
                from: "",
                to: "",
            };
            if (defaultValue.from) {
                date.from = moment(defaultValue.from, "DD.MM.YYYY").toDate();
                rangeDate.from = defaultValue.from;
            }
            if (defaultValue.to) {
                date.to = moment(defaultValue.to, "DD.MM.YYYY").toDate();
                rangeDate.to = defaultValue.to;
            }

            this.setState({rangeDate, ...date})
        }
    }

    getInitialState = () => {
        return {
            from: undefined,
            to: undefined,
        };
    }

    handleDialog = (dialog: boolean) => this.setState({dialog})

    handleDayClick = (day: any) => {
        let data: any = Object.assign({}, this.state);
        const range = DateUtils.addDayToRange(day, data);
        this.setState({...range});
    }

    handleResetClick = () => {
        const data: any = this.getInitialState();
        this.setState({...data});
    }

    getDialogTitle = () => {
        const {from, to} = this.rangeDateStr();
        let strFrom = from?from:"__.__.____", srtTo = to?to:"__.__.____";
        return `Период размещения с ${strFrom} по ${srtTo}`;
    }

    getSelectedDays = () => {
        let data: any = Object.assign({}, this.state);
        return [data.from, {from: data.from, to: data.to}];
    }

    rangeDateStr = () => {
        const {from, to} = this.state;
        let strFrom = "", strTo = "";

        try {
            if (from) strFrom = moment(from).format('DD.MM.YYYY');
            if (to) strTo = moment(to).format('DD.MM.YYYY');
        } catch (error) {

        }
        return {from: strFrom, to: strTo};
    }

    handleApply = () => {
        const rangeDate = this.rangeDateStr();
        if (rangeDate.from && rangeDate.to) {
            this.props.setFilterRangeDate(rangeDate);
            this.setState({rangeDate, dialog: false})
        }
    }

    render = () => {
        const self = this,
            {dialog, from, to, rangeDate} = self.state,
            modifiers = {start: from, end: to},
            inputValue = (rangeDate.from && rangeDate.to)?`${rangeDate.from}-${rangeDate.to}`:"";

        return (
            <>
                <FormControl fullWidth>
                    <InputLabel
                        shrink={inputValue?true:false}>
                        Период размещения
                    </InputLabel>
                    <Input
                        className="cursor-pointer"
                        onClick={() => self.handleDialog(true)}
                        disabled={true}
                        value={inputValue}
                        endAdornment={(
                            <InputAdornment position="end">
                                <DateRangeIcon />
                            </InputAdornment>
                        )}/>
                </FormControl>
                <Dialog onClose={() => self.handleDialog(false)} open={dialog} maxWidth="md">
                    <DialogTitle>
                        {self.getDialogTitle()}
                    </DialogTitle>
                    <DialogContent>
                        <DayPicker
                            {...propsPicker}
                            className="Selectable"
                            modifiers={modifiers}
                            selectedDays={self.getSelectedDays()}
                            onDayClick={self.handleDayClick} />
                        <DialogContentText className="text-center mb-0">
                            {!from && !to && 'Пожалуйста, выберите первый день.'}
                            {from && !to && 'Пожалуйста, выберите последний день.'}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={self.handleResetClick}
                            color="secondary">
                            Сбросить
                        </Button>
                        <Button
                            onClick={self.handleApply}
                            color="primary">
                            Подтвердить
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
}

export default DataPicker;

// https://react-day-picker.js.org/examples/selected-range
