import React from "react";
import {Link} from "react-router-dom";
import {Container, Grid} from '@material-ui/core';
import {style} from "typestyle";
import hexToRgba from 'hex-to-rgba';

import {IItemAdvertisingCatalog, IAdvertisingCatalog} from "./index.d";

const AdvertisingCatalog = ({title, items}: IAdvertisingCatalog) => {
    return (
        <section id="advertising-catalog">
            <Container fixed>
                {title?(
                    <div className="title">{title}</div>
                ):null}
                <Grid
                    className="grid-container"
                    container
                    justify="center"
                    alignItems="center"
                    spacing={5}>
                    {items.map((item: IItemAdvertisingCatalog, key: number) => {
                        const cardClass = style({
                            transition: 'background .2s',
                            background: '#ffffff',
                            padding: '40px 0',
                            borderRadius: '5px',
                            boxShadow: "rgba(150, 150, 150, 0.1) 0px 0px 20px 0px",
                            $nest: {
                                '&:hover': {
                                    background: item.color ? hexToRgba(item.color, '0.3') : hexToRgba("#ffffff", '0.3')
                                }
                            }
                        });
                        return (
                            <Grid
                                key={key}
                                item
                                xs={4}>
                                <Link
                                    to={`/catalog/${item.url}`}
                                    className="card"
                                    title={item.name}>
                                    <div
                                        className={cardClass}>
                                        <div className="image">
                                            <img src={item.image} alt={item.name} />
                                        </div>
                                        <div className="name">
                                            {item.name}
                                        </div>
                                    </div>
                                </Link>
                            </Grid>
                        )
                    })}
                </Grid>
            </Container>
        </section>
    )
}

export default AdvertisingCatalog;
