import React from "react";
import {Dialog, DialogContent} from '@material-ui/core';

type Props = {
    open: boolean,
    handleClose: any,
    title: string,
    message: string
}

const DialogMessage: React.FC<Props> = ({open, handleClose, title, message}) => {
    return (
        <Dialog
            open={open}
            keepMounted
            onClose={handleClose}>
                <DialogContent>
                    <div className="dialog-grid">
                        <div className="images-grid">
                            <div />
                        </div>
                        <div className="description-grid">
                            <div>
                                <div className="title">{title}</div>
                                <div
                                    className="message"
                                    dangerouslySetInnerHTML={{
                                        __html: message
                                    }}>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
        </Dialog>
    )
}

export default DialogMessage;
