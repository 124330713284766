import React from 'react'
import {Grid} from '@material-ui/core';
import {EnumAuthForm} from "../../../store/models/index.d";

const ChangeGrid = ({
    fnAuthDialog,
    formType
}: {
    fnAuthDialog: any
    formType: EnumAuthForm
}) => {
    return (
        <Grid
            className="grid-form-change"
            container
            spacing={5}>
            <Grid item xs={6}>
                <button
                    className={formType === EnumAuthForm.Login?"active":""}
                    onClick={() => fnAuthDialog({formType: EnumAuthForm.Login})}>Вход</button>
            </Grid>
            <Grid item xs={6}>
                <button
                    className={formType === EnumAuthForm.CheckIn?"active":""}
                    onClick={() => fnAuthDialog({formType: EnumAuthForm.CheckIn})}>Регистрация</button>
            </Grid>
        </Grid>
    )
}

export default ChangeGrid;
