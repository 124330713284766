import React from 'react'
import {connect} from "react-redux";
// import {Link, NavLink} from "react-router-dom";
import {Link} from "react-router-dom";
import {Container} from '@material-ui/core';

import routes from "../../router/routes";
import {TLink} from "../../router/index.d";
import {IUser, ISetting, TPage} from "../../store/models/index.d";

import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import EmailIcon from '@material-ui/icons/Email';

const getHeadeLinks = (pages: TPage[]) => {
    const entries = Object.entries(routes);
    let links: TLink[] = [];
    if (entries.length > 0) {
        links.push(entries[0][1]);
    }
    pages.forEach((page: TPage) => {
        if (page.showMenu) {
            links.push({
                name: page.name,
                to: `/${page.url}`
            } as TLink);
        }
    })
    if (entries.length > 1) {
        for (let index = 1; index < entries.length; index++) {
            links.push(entries[index][1]);
        }
    }
    // let links: TLink[] = Object.entries(routes).map((arr: any[]) => {
    //     return arr[1];
    // });
    // return links;
    return links;
}

const Header: React.FC<{
    user: IUser, fnAuthDialog: any, setting: ISetting, pages: TPage[]
}> = ({user, fnAuthDialog, setting, pages}) => {
    const [count, setCount] = React.useState(0);
    return (
        <header id="header">
            <Container fixed>
                <div className="grid-container">
                    <div className="grid-logo">
                        <Link to="/">
                            <div></div>
                        </Link>
                    </div>
                    <div className="grid-menu">
                        <ul>
                            {getHeadeLinks(pages).map((val: TLink, idx: number) => {
                                let selected = val.to === window.location.pathname ? true : false;
                                return (
                                    <li key={idx}>
                                        <Link to={val.to} className={selected?"selected":""} onClick={() => setCount(count+1)}>
                                            {val.name}
                                        </Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className="grid-to-contact-us">
                        <div>
                            {setting.email && <a href={`mailto:${setting.email}`} target="_blank"><EmailIcon /> написать на e-mail</a>}
                            {setting.whatsappPhone && <a href={`https://wa.me/7${setting.whatsappPhone}`} target="_blank"><WhatsAppIcon /> консультация по whatsapp</a>}
                        </div>
                    </div>
                    <div className="grid-personalArea">
                        {user.token?(
                            <Link to="/cabinet">
                                {`${user.surname} ${user.name}`}
                            </Link>
                        ):(
                            <button onClick={() => fnAuthDialog({show: true})}>
                                Войти
                            </button>
                        )}
                    </div>
                </div>
            </Container>
        </header>
    )
}

const mapState = (state: any) => {
    return {
        user: state.user,
        setting: state.setting,
        pages: state.home.pages,
    };
}, mapDispatch = (dispatch: any) => {
    return {
        fnAuthDialog: (data: any) => dispatch.authorization.dialog(data),
    };
}

export default connect(mapState, mapDispatch)(Header);
