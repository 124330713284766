import React from "react";
import {Grid} from 'react-virtualized';
import {ICalculateMediaPlan_AdditionalFilterValues as IAdditional} from "store/models/index.d";
import ReactTooltip from 'react-tooltip';
import { useDispatch } from "react-redux";

type Props = {
    additionalFilterValues: IAdditional,
    columns: any[],
    getColumnWidth: any,
    headerOnClick: any,
    rowHeight: number,
    width: number,
    defaultValue: number,
}

const cellRenderer = (
    grid: any,
    columns: any,
    headerOnClick: any,
    additionalFilterValues: IAdditional,
    sort: TSort,
    setSort: (value: TSort) => void
) => {
    const {columnIndex, key, style} = grid;
    let className = "GridColumn GridHeader",
        value = columns[columnIndex].caption;

    if (columnIndex === 0) className += " no-border";
    if (columnIndex === columns.length - 1) className += " no-border";
    if (columns[columnIndex]?.noBorder) className += " no-border";
    if (columns[columnIndex]?.options?.length > 1 && !columns[columnIndex].sorteble) {
        let filterMap = additionalFilterValues[columns[columnIndex].filterName]?additionalFilterValues[columns[columnIndex].filterName]:[];
        let count = filterMap.length?filterMap.length:0;
        value = (
            <span className="selected-field">
                <span>
                    {value + (count?` (${count})`: "")}
                </span>
                <span data-tip={"Фильтры"} onClick={() => headerOnClick(columns[columnIndex])} className="filter-button"></span>
            </span>
        )
    } else if (columns[columnIndex]?.options?.length > 1 && columns[columnIndex].sorteble) {
        let filterMap = additionalFilterValues[columns[columnIndex].filterName]?additionalFilterValues[columns[columnIndex].filterName]:[];
        let count = filterMap.length?filterMap.length:0;
        let name: string = columns[columnIndex].name;

        value = (
            <span className={`selected-field sorteble-and-selected-field`}>
                <span  className={`sortfield ${name === sort.field ? (sort.type === "ASC" ? "ASC" : "DESC") : "ASC"}`} onClick={() => {
                    setSort({
                        field: name,
                        type: sort.type === "ASC" ? "DESC" : "ASC"
                    })
                }}>
                    {value + (count?` (${count})`: "")}
                </span>
                <span data-tip={"Фильтры"} onClick={() => headerOnClick(columns[columnIndex])} className="filter-button"></span>
            </span>
        )
    } else if (columns[columnIndex].sorteble) {
        let name = columns[columnIndex].name;
        value = (
            <span className={`sorteble-field ${name === sort.field ? (sort.type === "ASC" ? "ASC" : "DESC") : "ASC"}`}
                onClick={() => {
                    setSort({
                        field: name,
                        type: sort.type === "ASC" ? "DESC" : "ASC"
                    })
                }}>
                <span>{value}</span>
            </span>
        )
    }
    return (
        <div key={key} style={style} className={className}>
            {value}
        </div>
    );
}

type TSort = {
    field: string,
    type: string
}

const Header: React.FC<Props> = ({
    additionalFilterValues,
    columns,
    getColumnWidth,
    headerOnClick,
    rowHeight,
    width,
    defaultValue
}) => {
    const [sort, setSort] = React.useState<TSort>({field: "", type: ""});
    const dispatch: any = useDispatch(),
    headerSetSort = (value: TSort) => {
        setSort(value);
        dispatch.calculateMediaPlan.setSort(value)
    }
    return (
        <div className="table-header">
            <Grid
                className="GridRow"
                overscanColumnCount={0}
                overscanRowCount={10}
                cellRenderer={(grid: any) => cellRenderer(grid, columns, headerOnClick, additionalFilterValues, sort, headerSetSort)}
                columnWidth={(grid: any) => getColumnWidth(grid, columns, defaultValue)}
                columnCount={columns.length}
                rowHeight={rowHeight}
                rowCount={1}
                height={rowHeight}
                width={width}
            />
            <ReactTooltip place="bottom" type="dark" effect="float" />
        </div>
    )
}
export default Header;
