import React, {Component} from 'react';

import Pagination from '@material-ui/lab/Pagination';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import {ICabinet, IMediaPlan} from "../index.d";
import Skeleton from 'react-loading-skeleton';
import Controls from "./controls";
import ViewMediaPlan from "./viewMediaPlan";

interface StateMediaPlans {
    loading: boolean,
    filter: {
        page: number,
        dateSort: string,
        search: string
    },
    dialog: {
        open: boolean,
        searchInput: string
    },
    viewMediaPlan: {
        open: boolean,
        id: number
    }
}

class MediaPlans extends Component<ICabinet, StateMediaPlans> {

    readonly state = {
        loading: true,
        filter: {
            page: 1,
            dateSort: 'asc',
            search: ""
        },
        dialog: {
            open: false,
            searchInput: ""
        },
        viewMediaPlan: {
            open: false,
            id: 0
        }
    }

    componentDidMount = () => {
        this.getMediaPlans();
    }

    getMediaPlans = () => {
        const self = this,
            {filter} = self.state,
            {fnGetMediaPlans} = self.props;

        fnGetMediaPlans(filter, (isError: boolean) => {
            if (!isError) self.setState({loading: false})
        })
    }

    handleChangePage = (event: any, page: number) => {
        const self = this,
            filter = Object.assign({}, self.state.filter, {page});

        this.setState({filter}, () => {
            self.getMediaPlans();
        })
    }

    handleChangeSort = () => {
        const self = this;
        let filter = Object.assign({}, self.state.filter);
        if (filter.dateSort === "asc") filter.dateSort = "desc";
        else filter.dateSort = "asc";

        this.setState({filter}, () => {
            self.getMediaPlans();
        })
    }

    handleCloseDialog = (apply: boolean) => {
        const self = this,
            state = self.state;

        let dialog = Object.assign({}, state.dialog);
        dialog.open = !dialog.open;
        this.setState({dialog}, () => {
            if (apply) {
                let filter = Object.assign({}, state.filter);
                filter.search = dialog.searchInput;
                self.setState({filter}, () => {
                    self.getMediaPlans();
                })
            }
        })
    }

    handleSearchInput = (e: any) => {
        let dialog = Object.assign({}, this.state.dialog);
        dialog.searchInput = e.target.value;
        this.setState({dialog})
    }

    clearSearch = () => {
        const self = this;
        let dialog = Object.assign({}, this.state.dialog),
            filter = Object.assign({}, this.state.filter);
        dialog.searchInput = "";
        filter.search = "";
        this.setState({dialog, filter}, () => {
            self.getMediaPlans();
        })
    }

    deleteMediaPlans = (id: number): void => {
        const self = this;
        self.props.fnDeleteMediaPlans(id, (error: boolean) => {
            if (!error) self.getMediaPlans();
        })
    }

    handleViewMediaPlan = (open: boolean, id: number) => {
        this.setState({viewMediaPlan: {open, id}})
    }

    render = () => {
        const self = this,
            {loading, filter, dialog, viewMediaPlan} = self.state,
            {user, fnDownloadMediaPlans} = self.props,
            {items, currentPage, totalPage} = user.mediaPlans;

        if (loading) return <Skeleton height={323} />
        return (
            <div>
                <table>
                    <thead>
                        <tr>
                            <th>
                                <div>
                                    Название медиаплана
                                    {filter.search?<small>{filter.search}</small>:null}
                                    {filter.search?(
                                        <span
                                            onClick={self.clearSearch}
                                            className="search-btn cursor-pointer">
                                            <CloseIcon />
                                        </span>
                                    ):(
                                        <span
                                            onClick={() => self.handleCloseDialog(false)}
                                            className="search-btn cursor-pointer">
                                            <SearchIcon />
                                        </span>
                                    )}
                                </div>
                            </th>
                            <th>
                                <div>
                                    Статус
                                </div>
                            </th>
                            <th>
                                <div
                                    className="cursor-pointer"
                                    onClick={() => self.handleChangeSort()}>
                                    Дата
                                    <span className={`sort-arrow ${filter.dateSort}`}>
                                        <ArrowDownwardIcon />
                                    </span>
                                </div>
                            </th>
                            <th className="actions"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((val: IMediaPlan, idx: number) => (
                            <tr key={idx}>
                                <td>
                                    <button className="link" onClick={() => self.handleViewMediaPlan(true, val.id)}>
                                        {val.name}
                                    </button>
                                </td>
                                <td>{val.status}</td>
                                <td>{val.date}</td>
                                <td className="actions text-center">
                                    <Controls
                                        fnDownloadMediaPlans={fnDownloadMediaPlans}
                                        deleteMediaPlans={self.deleteMediaPlans}
                                        id={val.id}/>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {totalPage>0?(
                    <div className="pagination">
                        <div>
                            <Pagination
                                onChange={self.handleChangePage}
                                count={totalPage}
                                page={currentPage}
                                color="primary"/>
                        </div>
                    </div>
                ):null}
                <Dialog open={dialog.open} onClose={() => self.handleCloseDialog(false)} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Поиск по наименованию медиаплана</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Название медиаплана"
                            value={dialog.searchInput}
                            onChange={self.handleSearchInput}
                            fullWidth/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => self.handleCloseDialog(false)} color="primary">
                            Закрыть
                        </Button>
                        <Button onClick={() => self.handleCloseDialog(true)} color="primary">
                            Применить
                        </Button>
                    </DialogActions>
                </Dialog>
                <ViewMediaPlan
                    visible={viewMediaPlan.open}
                    handleClose={() => self.handleViewMediaPlan(false, 0)}
                    updateTableMediaPlans={self.getMediaPlans}
                    id={viewMediaPlan.id}/>
            </div>
        )
    }
}

export default MediaPlans;
