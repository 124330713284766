import React from "react"
import {withRouter} from "react-router-dom";
import Helmet from "react-helmet";
import {connect} from "react-redux";
import { IItemAdvertisingCatalog } from "components/advertisingCatalog/index.d";
import lodash from "lodash";
import { TMeta } from "store/models/index.d";

const initialState = {
    title: "InGood - Маркетплейс рекламы",
    description: "Хотите рекламное место на самых заметных местах в городе? Готовы быть узнаваемыми буквально на каждом шагу? Закажите наружную рекламу у нас в InGood! Общественный транспорт, торговые сети, лифты бизнес-центров и жилых домов - вас увидят все. Звоните: 8 (800) 222-53-91"
}

type TState = {
    title: string,
    description: string,
}

class HelmetAap extends React.Component<any, TState> {

    readonly state = initialState;
  
    private unlisten = () => {}

    componentDidMount = () => {
        this.checkCatalogPages(window.location.pathname);
    }

    componentDidUpdate = (prevProps: any) => {
        if (!this.props.loading && prevProps.loading) {
            this.checkCatalogPages(window.location.pathname);
        }
    }

    componentWillMount = () => {
        this.unlisten = this.props.history.listen((location: any, action: any) => {
            this.checkCatalogPages(location.pathname);
        });
    }

    componentWillUnmount = () => {
        this.unlisten();
    }

    checkCatalogPages = (pathname: string) => {
        if  (pathname === "/cases") {
            this.setState({
                title: "InGood - Готовые кейсы нашей компании",
                description: "Реклама, которую мы, с любовью, уже сделали. Закажите наружную рекламу у нас в InGood! Общественный транспорт, торговые сети, лифты бизнес-центров и жилых домов - вас увидят все. Звоните: 8 (800) 222-53-91",
            })
        } else {
            const {catalog} = this.props;
            if (catalog && Array.isArray(catalog.items)) {
                let key = -1;
                catalog.items.forEach((item: IItemAdvertisingCatalog, idx: number) => {
                    if (`/catalog/${item.url}` === pathname) {
                        key = idx;
                    }
                })
                if (key !== -1) {
                    this.setState({
                        title: `${catalog.items[key].name}`,
                        description: catalog.items[key].description,
                    })
                } else this.setState(initialState)
            } else this.setState(initialState)
        }
    }

    getMettaData = () => {
        const meta: TMeta[] = this.props.meta;
        let response = {
            title: "", description: ""
        }
        let key = lodash.findIndex(meta, (m: TMeta) => m.uri ===  window.location.pathname);
        if (key !== -1) {
            response.title = meta[key].title;
            response.description = meta[key].description;
        }
        return response;
    }

    render() {
        const {title, description} = this.state,
        meta = this.getMettaData();

        return (
            <>
                <Helmet>
                    <title>{meta.title?meta.title:title}</title>
                    {meta.description && <meta name="description" content={meta.description} />}
                    <script type="application/ld+json"> 
                        {`{
                            "@context": "http://www.schema.org",
                            "@type": "Organization",
                            "name": "${title}",
                            "description": "${description}",
                            "url": "https://ingood.ru/",
                            "logo": "https://ingood.ru/static/media/logo.6a38cc86.png",
                            "image": "https://ingood.ru/static/media/logo.6a38cc86.png",
                            "contactPoint": {
                                "@type": "ContactPoint",
                                "telephone": " 8 (800) 222-53-91",
                                "contactType": "buying_admin@ingood.ru"
                            }
                        }`}
                    </script>
                </Helmet>
                <div>
                    {this.props.children}
                </div>
            </>
        );
    }
}

const mapState = (state: any) => {
    return {
        catalog: state.home.advertisingCatalog,
        meta: state.home.meta,
        loading: state.home.loading,
    };
};

export default withRouter(connect(mapState)(HelmetAap));