import {parseError} from "./answerFn";
const prefix: string = "/api/";

export interface IResponse {
    status: number,
    data: any
}

export const post = (url: string, data: any, callback: any) => {
    fetch(prefix + url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: data?JSON.stringify(data):null,
    })
    .then((response: any) => {
        const statusCode = response.status;
        let data = [];
        try {
            data = response.json();
        } catch (e) {

        }
        return Promise.all([statusCode, data]);
    })
    .then((response: any[]) => {
        if (response[0] === 200) {
            return callback({
                status: response[0],
                data: response[1]
            }, false);
        } else {
            parseError(response[1], response[0]);
            return callback({}, true);
        }
    })
    .catch((error: any) => {
        return callback(null, true);
    });
}

export const postAuth = (token: string, url: string, data: any, callback: any) => {
    fetch(prefix + url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: data?JSON.stringify(data):null,
    })
    .then((response: any) => {
        const statusCode: number = response.status,
            data: any = response.json();

        return Promise.all([statusCode, data]);
    })
    .then((response: any[]) => {
        if (response[0] === 200) {
            return callback({
                status: response[0],
                data: response[1]
            }, false);
        } else {
            parseError(response[1], response[0]);
            return callback({}, true);
        }
    })
    .catch((error: any) => {
        return callback(null, true);
    });
}

export const getApi = (url: string, data: any, callback: any) => {
    if (Object.keys(data).length) {
        console.warn("data не пустая", data);
    }
    let prefixCrm: string = "https://crm.ingood.ru/";
    // let prefixCrm: string = "http://localhost:5004/";
    fetch(prefixCrm + url)
    .then((response: any) => {
        const statusCode: number = response.status,
            data: any = response.json();
        return Promise.all([statusCode, data]);
    })
    .then((response: any[]) => {
        if (response[0] === 200) {
            return callback({
                status: response[0],
                data: response[1]
            }, false);
        } else {
            parseError(response[1], response[0]);
            return callback({}, true);
        }
    })
    .catch((error: any) => {
        return callback(null, true);
    });
}

export const postApi = (url: string, data: any, callback: any) => {
    let prefixCrm: string = "https://crm.ingood.ru/";
    // let prefixCrm: string = "http://localhost:5004/";
    fetch(prefixCrm + url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: data?JSON.stringify(data):null,
    })
    .then((response: any) => {
        const statusCode: number = response.status,
            data: any = response.json();
        return Promise.all([statusCode, data]);
    })
    .then((response: any[]) => {
        if (response[0] === 200) {
            return callback({
                status: response[0],
                data: response[1]
            }, false);
        } else {
            parseError(response[1], response[0]);
            return callback({}, true);
        }
    })
    .catch((error: any) => {
        return callback(null, true);
    });
}
