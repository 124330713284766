import {post, IResponse} from '../fetchUp';
import {ISetting} from "./index.d";

const initialState: ISetting = {
	meta_title: "",
	meta_description: "",
	meta_keywords: "",
	email: "",
	phone: "",
	whatsappPhone: "",
	address: "",
	user_agreement_link: "",
	figuresAboutUsTitle: "",
	figuresAboutUsSignature: "",
	presentation: "",
	instruction: "",
	openingTimes: "",
}

const setting = {
	state: initialState,
	reducers: {
		update(state: any, payload: any) {
            return {...state, ...payload}
        },
    },
    effects: (dispatch: any) => ({
		init(payload: any, rootState: any) {
        	post('setting', {}, (response: IResponse, error: boolean) => {
				if (!error) dispatch.setting.update(response.data)
			})
        },
		senfFeedback(payload: any, rootState: any) {
			const {formValues, callback} = payload;
			post('feedback', formValues, (response: IResponse, error: boolean) => {
				if (callback) callback(response)
			})
		},
    })
}

export default setting
