import React from "react";
import {Container, Grid} from "@material-ui/core";
import {connect} from "react-redux";
import {BaseProps} from "screens";
import {IHome} from "../../store/models/index.d";

interface ICases extends BaseProps {
    home: IHome
}

type TCases = {
    id: number,
    logo: string | null,
    name: string,
    description: string,
    // Левый столбец
    leftNumber: number,
    leftName: string,
    leftDescription: string,
    // Правый столбец
    rightNumber: number,
    rightName: string,
    rightDescription: string,
    // Изображения
    filePosition: number, // 0 -> Сверху | 1 -> Слева
    file_1: string | null,
    file_2: string | null,
    file_3: string | null,
}

class Cases extends React.PureComponent<ICases> {

    componentDidMount = () => {
        window.scrollTo(0, 0);
    }

    render = () => {
        const {home} = this.props;
        return (
            <div id="page-catalog" className="cases-page">
                <Container fixed>
                    {Array.isArray(home.cases) && home.cases.length > 0 && (
                        <div>
                            {home.cases.map((item: TCases, idx) => {
                                return (
                                    <Grid container spacing={3} className="cases-row" key={idx}>
                                        <Grid item xs={6}>
                                            {item.logo !== null && (
                                                <div className="logo-container">
                                                    <img src={"https://ingood.ru" + item.logo} title={item.name} />
                                                </div>
                                            )}
                                            <div className="main-description">
                                                {item.description}
                                            </div>
                                            <Grid container spacing={9}>
                                                <Grid item xs={6} className="info-block">
                                                    <div className="number">{item.leftNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</div>
                                                    <div className="name">{item.leftName}</div>
                                                    <div className="description">{item.leftDescription}</div>
                                                </Grid>
                                                <Grid item xs={6} className="info-block">
                                                    <div className="number">{item.rightNumber}</div>
                                                    <div className="name">{item.rightName}</div>
                                                    <div className="description">{item.rightDescription}</div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div className={item.filePosition === 0?"images-top":"images-left"}>
                                                <div className="main" style={item.file_1 ? {backgroundImage: `url(https://ingood.ru${item.file_1})`} : {}}> </div>
                                                <div className="additional">
                                                    <div className="left" style={item.file_2 ? {backgroundImage: `url(https://ingood.ru${item.file_2})`} : {}}></div>
                                                    <div className="right" style={item.file_3 ? {backgroundImage: `url(https://ingood.ru${item.file_3})`} : {}}></div>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </div>
                    )}
                </Container>
            </div>
        )
    }
}


const mapState = (state: any) => {
    return {
        home: state.home
    };
};

export default connect(mapState)(Cases);
