import scrollbarSize from 'dom-helpers/scrollbarSize';

export const getColumns = () => {
    let columns = [
        {name: "region", caption: "Регион", maxWidth: 200, options: [], filterName: ""},
        {name: "city", caption: "Город", maxWidth: 200, options: [], filterName: ""},
        {name: "serviceType", caption: "Направление", width: 150, options: [], filterName: ""},
        {name: "name", caption: "Описание", options: [], filterName: ""},
        {name: "channel", caption: "Носитель", width: 150, options: [], filterName: ""},
        {name: "size", caption: "Формат", width: 120, options: [], filterName: ""},
        {name: "due", caption: "Сроки, дни", width: 137, options: [], filterName: ""},
        {name: "amount", caption: "Количество, выходов в день", width: 137, options: [], filterName: ""},
        {name: "price", caption: "Стоимость, ₽", width: 137, options: [], filterName: ""},
        {name: "sale", caption: "", width: 50, options: [], filterName: ""},
        {name: "delete", caption: "", width: 50, options: [], filterName: "", noBorder: true},
    ];

    if (scrollbarSize() > 0) {
        columns.push({name: "scrollbar", caption: "", width: scrollbarSize(), options: [], filterName: "", noBorder: true})
    }

    return columns;
}
