import React, {Component} from "react";
import {connect} from "react-redux";
import {Grid} from "@material-ui/core";

import {Option} from "./index.d";
import AutoComplete from "./autocomplete";

import {ICalculateMediaPlan_FilterValues as State} from "store/models/index.d";
const _ = require('lodash');

type Props = {
    isEdit: boolean,
    formValues: State,
    fnSetFilterValues: any,
    fnFilterApply: any,
    fnResetFilterValues: any
}

type TState = {
    loading: boolean,
    allowedToSend: boolean,
}

class Filter extends Component<Props, TState> {

    readonly state = {
        loading: true,
        allowedToSend: false
    }

    componentDidMount = () => {
        const self = this;
        if (self.props.isEdit) self.setState({loading: false})
        else self.props.fnResetFilterValues().then(() => {
            self.setState({loading: false})
        })
    }

    setFiterValues = (name: string, selectedValues: Option[]) => {
        const self = this,
            {formValues} = self.props;
        let values: number[] = [],
            fields: State = Object.assign({}, formValues);

        selectedValues.forEach((item: Option) => {
            if (_.isArray(item.value)) {
                values = values.concat(item.value);
            } else {
                values.push(item.value);
            }
        });

        if (fields[name]) fields[name] = values;

        let allowedToSend = false;
        if (Array.isArray(fields.regions) && Array.isArray(fields.cities) && Array.isArray(fields.types)) {
            if (fields.regions.length && fields.cities.length && fields.types.length) {
                allowedToSend = true;
            }
        }
        self.props.fnSetFilterValues({...fields}).then(() => {
            self.setState({allowedToSend});
        });
    }

    render = () => {
        const self = this,
        {allowedToSend, loading} = self.state,
        state = self.props.formValues;
        if (loading) return null;

        return (
            <div className="filter">
                <Grid
                    className="input-grid"
                    container
                    spacing={3}>
                    <Grid item style={{width: '20%'}}>
                        <AutoComplete
                            url="api-out/get_regions/"
                            name="regions"
                            defaultValues={state.regions}
                            setFiterValues={self.setFiterValues}
                            label={<div>{"Регион"} <span>*</span></div>}
                            placeholder="Выберите регион"/>
                    </Grid>
                    <Grid item style={{width: '20%'}}>
                        <AutoComplete
                            url="api-out/get_cities/"
                            name="cities"
                            defaultValues={state.cities}
                            setFiterValues={self.setFiterValues}
                            label={<div>{"Город"} <span>*</span></div>}
                            depends={{
                                name: "regions",
                                values: state.regions
                            }}
                            placeholder="Выберите город"/>
                    </Grid>
                    <Grid item style={{width: '20%'}}>
                        <AutoComplete
                            url="api-out/get_types/"
                            name="types"
                            defaultValues={state.types}
                            setFiterValues={self.setFiterValues}
                            label={<div>{"Направление"} <span>*</span></div>}
                            placeholder="Выберите направление"/>
                    </Grid>
                    <Grid item style={{width: '20%'}}>
                        <AutoComplete
                            url="api-out/get_channels/"
                            name="channels"
                            defaultValues={state.channels}
                            setFiterValues={self.setFiterValues}
                            label="Носитель"
                            depends={{
                                name: "types",
                                values: state.types
                            }}
                            placeholder="Выберите носитель"/>
                    </Grid>
                    <Grid item style={{width: '20%'}}>
                        <AutoComplete
                            url="api-out/get_mediatypes/"
                            name="mediatypes"
                            defaultValues={state.mediatypes}
                            setFiterValues={self.setFiterValues}
                            label="Вид носителя"
                            depends={{
                                name: "channels",
                                values: state.channels
                            }}
                            placeholder="Выберите вид носителя"/>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={3}>
                    <Grid item style={{width: '80%'}}></Grid>
                    <Grid item style={{width: '20%'}}>
                        <button
                            disabled={!allowedToSend}
                            onClick={() => self.props.fnFilterApply(() => {})}
                            className="filter-apply">
                            Применить
                        </button>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapState = (state: any) => {
    return {
        formValues: state.calculateMediaPlan.filterValues,
    };
};

const mapDispatch = (dispatch: any) => {
    return {
        fnSetFilterValues: (formValues: any) => dispatch.calculateMediaPlan.setFilterValues(formValues),
        fnFilterApply: (callback: any) => dispatch.calculateMediaPlan.filterApply({callback}),
        fnResetFilterValues: () => dispatch.calculateMediaPlan.resetFilterValues({}),
    };
}

export default connect(mapState, mapDispatch)(Filter);
