import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Container, Grid} from '@material-ui/core';

import {ISetting} from "../../store/models/index.d";
import routes from "../../router/routes";
import {TLink} from "../../router/index.d";

const getHeadeLinks = () => {
    let links: TLink[] = Object.entries(routes).map((arr: any[]) => {
        return arr[1];
    });
    return links;
}

interface IFooter {
    setting: ISetting
}

const Footer = ({setting}: IFooter) => {
    return (
        <footer id="footer">
            <Container fixed>
                <Grid
                    container
                    spacing={5}>
                    <Grid item xs={3}>
                        <div className="logo"></div>
                        <div className="copyright-text">© InGood все права защищены, {new Date().getFullYear()}</div>
                    </Grid>
                    <Grid item xs={2}>
                        <div className="grid-menu">
                            <ul>
                                {getHeadeLinks().map((val: TLink, idx: number) => (
                                    <li key={idx}>
                                        <Link to={val.to}>{val.name}</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <a className="phone" href={`tel:${setting.phone}`} title={setting.phone}>
                            {setting.phone}
                        </a>

                        <div className="opening-times">
                            <p>{setting.openingTimes}</p>
                        </div>

                        <div className="partner">
                            {/* <p>Предложения о сотрудничестве для поставщиков рекламы:</p> */}
                            <p>Почта для запросов на размещение:</p>
                            <a href={`mailto:${setting.email}`} title={setting.email}>
                                {setting.email}
                            </a>
                        </div>
                        <div className="information">
                            <p>*Информация на сайте не является публичной офертой</p>
                        </div>
                    </Grid>
                    <Grid item xs={4} className="text-center">
                        {/* <a className="presentation" href={setting.presentation} title="Скачать презентацию">
                            Скачать презентацию
                        </a> */}
                    </Grid>
                </Grid>
            </Container>
        </footer>
    )
}

const mapState = (state: any) => {
    return {
        setting: state.setting,
    };
};

export default connect(mapState)(Footer);
