import React from "react";
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {useConfirm} from 'material-ui-confirm';

type Props = {
    id: number,
    deleteMediaPlans: any,
    fnDownloadMediaPlans: any
}

const Controls: React.FC<Props> = ({id, deleteMediaPlans, fnDownloadMediaPlans}) => {
    const confirm = useConfirm();

    const handleDelete = () => {
        confirm({
            title: "Вы действительно хотите удалить медиаплан?",
            description: "",
            confirmationText: "Да",
            cancellationText: "Нет",
            dialogProps: {
                open: true,
                maxWidth: "xs"
            },
            confirmationButtonProps: {
                color: "secondary"
            },
            cancellationButtonProps: {
                color: "primary"
            },
        })
        .then(() => {
            deleteMediaPlans(id)
        });
    }

    return (
        <>
            <IconButton onClick={() => fnDownloadMediaPlans(id)} aria-label="Скачать">
                <GetAppIcon fontSize="inherit" />
            </IconButton>
            <IconButton onClick={handleDelete} aria-label="Удалить">
                <DeleteForeverIcon fontSize="inherit" />
            </IconButton>
        </>
    )
}

export default Controls;
