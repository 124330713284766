import React from "react";
import {Container, Grid} from '@material-ui/core';

import {IFigures, IItemFigures} from "./index.d";
import ItemFigures from "./item.component";

const Figures = ({title, signature, figures}: IFigures) => {
    return (
        <section id="figures">
            <Container fixed>
                <div className="title-grid">
                    <div className="title">{title}</div>
                    <div className="signature">{signature}</div>
                </div>
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                    spacing={3}>
                    {figures.map((item: IItemFigures, key: number) => {
                        return <ItemFigures key={key} {...item} />
                    })}
                </Grid>
            </Container>
        </section>
    )
}

export default Figures;
