import React, {PureComponent} from "react";
import Gallery from "react-photo-gallery";
import Carousel, {Modal, ModalGateway} from "react-images";
import {Portal, Dialog, DialogContent} from '@material-ui/core';

import Plain from "./plain";
import {ICalculateMediaPlan_Items as IData} from "store/models/index.d";
import DialogTitle from "components/dialogTitle";

type TModifyDescription = {
    key: any,
    column: any,
    style: any,
    className: string,
    row: IData
}

const imagesDataSource = (images: string[]) => images.map((src: string) => {
    return {src: src, width: 4, height: 3}
});

class ModifyDescription extends PureComponent<TModifyDescription> {
    readonly state = {
        currentImage: 0,
        visible: false,
        viewerVisible: false
    }

    setVisible = (value: boolean) => this.setState({visible: value})

    setCurrentImage = (value: number) => this.setState({currentImage: value})

    setViewerVisible = (value: boolean) => this.setState({viewerVisible: value})

    openLightbox = (event: any, {photo, index}: any) => {
        this.setCurrentImage(index);
        this.setViewerVisible(true);
    }

    closeLightbox = () => {
        this.setCurrentImage(0);
        this.setViewerVisible(false);
    }

    render = () => {
        const self = this,
        {visible, viewerVisible, currentImage} = self.state,
        {key, style, className, column, row} = self.props;

        let onClick: any = () => {},
        therePictures = false,
        images: any[] = [],
        classActive = "";

        if (row.images && Array.isArray(row.images) && row.images.length > 0) {
            onClick = () => self.setVisible(true);
            therePictures = true;
            classActive = "therePictures";
            images = imagesDataSource(row.images);
        }
        return (
            <>
                <div key={key} style={style} className={`button-modifyDescription ${classActive} ${className}`} onClick={onClick}>
                    <Plain value={row[column.name]}/>
                </div>
                {therePictures?(
                    <Portal>
                        <Dialog
                            fullWidth={true}
                            maxWidth="md"
                            open={visible}
                            onClose={() => self.setVisible(false)}>
                            <DialogTitle onClose={() => self.setVisible(false)}>
                                Изображение
                            </DialogTitle>
                            <DialogContent>
                                 <Gallery photos={images} onClick={self.openLightbox} />
                            </DialogContent>
                        </Dialog>
                        <ModalGateway>
                            {viewerVisible?(
                                <Modal onClose={self.closeLightbox}>
                                    <Carousel
                                        currentIndex={currentImage}
                                        views={images.map((x: any) => {
                                            return {
                                                ...x,
                                                zIndex: 99999,
                                                srcset: x.srcSet,
                                                caption: x.title
                                            }
                                        })}/>
                                </Modal>
                            ):null}
                        </ModalGateway>
                    </Portal>
                ):null}
            </>
        );
    }
}

export default ModifyDescription;
