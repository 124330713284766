import React, {Component} from 'react';
import {TextField, IconButton} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import {getApi, IResponse} from 'store/fetchUp';
import {Option} from "./index.d";
const _ = require('lodash');

interface State {
    search: string,
    options: Option[],
    selectedValues: Option[],
    modificationКey: number
}
interface Props {
    url: string,
    label: any,
    placeholder: string,
    name: string,
    defaultValues: any[],
    setFiterValues: any,
    depends?: {
        name: string,
        values: any
    }
}

const twoObjectsAreEqual = (objectOne: any, objectTwo: any): boolean => {
    try {
        return JSON.stringify(objectOne) === JSON.stringify(objectTwo);
    } catch (e) {
        return false;
    }
}

class AutoComplete extends Component<Props, State> {

    readonly state = {
        options: [],
        selectedValues: [],
        search: "",
        modificationКey: 0
    }

    componentDidUpdate = async (prev: Props) => {
        if (prev.depends && this.props?.depends) {
            if (this.props.depends.values !== prev.depends.values) {
                this.componentDidMount(false);
            }
        }
        if (JSON.stringify(this.props.defaultValues) !== JSON.stringify(prev.defaultValues)) {
            this.setSelectedValueByDefaultValues();
        }
    }

    componentDidMount = (setDefaultValues = true) => {
        const self = this,
            {url, depends} = self.props;

        let additional: string = "";
        if (depends && depends.values.length) {
            additional += "?";
            depends.values.forEach((item: number, idx: number) => {
                additional += `${depends.name}=${item}`;
                if (idx !== depends.values.length - 1) additional +="&";
            });
        }
        
        getApi(url + additional, {}, (response: IResponse, error: boolean) => {
            if (!error && response.status === 200) {
                self.setState({options: response.data}, () => {
                    if (setDefaultValues) self.setSelectedValueByDefaultValues();
                });
            }
        })
    }

    setSelectedValueByDefaultValues = () => {
        const {options} = this.state;
        const {name, defaultValues, setFiterValues} = this.props;
        let selectedValues: Option[] = [];
        if (Array.isArray(defaultValues)) {
            defaultValues.forEach((value: string | number) => {
                let key = _.findIndex(options, (o: Option) => { return o.value + "" === value + ""});
                if (key !== -1) {
                    selectedValues.push(options[key]);
                }
            })
        }
        this.setState({selectedValues}, () => {
            if (setFiterValues) setFiterValues(name, selectedValues);
        });
    }

    setValue = (event: any, value: Option) => {
        const self = this,
            {modificationКey} = self.state,
            {name, setFiterValues} = self.props;
        if (value) {
            let selectedValues: Option[] = Object.assign([], self.state.selectedValues),
                key = _.findIndex(selectedValues, (o: Option) => { return o.value === value.value});

            if (key === -1) {
                selectedValues.push(value)
                self.setState({selectedValues, search: "", modificationКey: modificationКey + 1}, () => {
                    if (setFiterValues) setFiterValues(name, selectedValues);
                })
            } else self.setState({search: "", modificationКey: modificationКey + 1})
        }
    }

    onChangeText = (event: any) => {
        if (event?.target) this.setState({search: event.target.value})
    }

    deleteSelectedValues = (item: Option) => {
        const self = this,
            {name, setFiterValues} = self.props;
        let selectedValues: Option[] = [];

        self.state.selectedValues.forEach((option: Option) => {
            if (option.value !== item.value) selectedValues.push(option)
        });

        self.setState({selectedValues}, () => {
            if (setFiterValues) setFiterValues(name, selectedValues);
        })
    }

    getOption = () => {
        const self = this,
        {selectedValues, options} = self.state;

        let list: Option[] = [];
        options.forEach((val: Option) => {
            let isSelected = false;
            selectedValues.forEach((selected: Option) => {
                if (twoObjectsAreEqual(val, selected)) {
                    isSelected = true;
                    return;
                }
            });
            if (!isSelected) list.push(val);
        });
        return list;
    }

    render() {
        const self = this,
            {label, placeholder} = self.props,
            {selectedValues, search, modificationКey} = self.state;
        return (
            <div className="autocomplete-box">
                <div className="label">{label}</div>
                <Autocomplete
                    key={modificationКey}
                    inputValue={search}
                    options={self.getOption()}
                    disableClearable={true}
                    clearOnBlur={true}
                    onChange={(event: any, newValue: any) => self.setValue(event, newValue)}
                    getOptionLabel={(option: Option) => option.name?option.name:""}
                    noOptionsText="Нет данных"
                    renderInput={params => {
                        return (
                            <TextField
                                {...params}
                                value={search}
                                placeholder={placeholder}
                                onChange={self.onChangeText}/>
                        )
                    }}/>
                {selectedValues.length?(
                    <ul className="selected-values">
                        {selectedValues.map((item: Option, idx) => {
                            return (
                                <li key={idx}>
                                    <div className="name">{item.name}</div>
                                    <div className="control">
                                        <IconButton
                                            onClick={() => self.deleteSelectedValues(item)}
                                            color="primary"
                                            size="small"
                                            component="span">
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                ):null}
            </div>
        )
    }
}
export default AutoComplete;
