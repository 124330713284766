import React, {PureComponent} from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import {makeStyles} from '@material-ui/core/styles';

type Props = {
    value: string
}

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: "#222",
    },
    tooltip: {
        backgroundColor: "#222",
        fontSize: 14,
        fontWeight: 400,
        opacity: 0.3,
    },
}));

const BootstrapTooltip = (props: any) => {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

class Plain extends PureComponent<Props> {

    private _maxHeight = 16;
    private _cell = React.createRef<HTMLDivElement>();

    readonly state = {
        tooltip: false
    }

    componentDidMount = () => {
        const self = this;
        if (self._cell?.current?.offsetHeight) {
            if (self._maxHeight < self._cell.current.offsetHeight) {
                self.setState({tooltip: true});
            }
        }
    }

    render = () => {
        const self = this,
        {tooltip} = self.state,
        {value} = self.props;

        if (tooltip) {
            return (
                <BootstrapTooltip title={value}>
                    <span ref={self._cell}>
                        {value}
                    </span>
                </BootstrapTooltip>
            )
        } else {
            return (
                <span className="tooltip" ref={self._cell}>
                    {value}
                </span>
            )
        }
    }
}

export default Plain;
