const _ = require('lodash');

export const parseError = (dataSource, status) => {
    let content = "Что-то пошло не так. Попробуйте еще раз";
    if (status === 401) return;
    if (!_.isUndefined(dataSource.errors)) {
        if (_.isObject(dataSource.errors)) {
            content = "";
            Object.keys(dataSource.errors).forEach((key, index) => {
                content += dataSource.errors[key][0].trim() + "\n";
            });
        } else if (_.isString(dataSource.errors)) {
            content = dataSource.errors;
        }
    } else if (!_.isUndefined(dataSource.danger) && _.isString(dataSource.danger)) {
        content = dataSource.danger;
    } else if (!_.isUndefined(dataSource.danger) && _.isArray(dataSource.danger)) {
        content = "";
        dataSource.danger.forEach((val, idx) => {
            content += (idx+1) + ") " + val + "\n";
        })
    } else if (!_.isUndefined(dataSource.message) && _.isString(dataSource.message)) {
        content = dataSource.message;
    }

    if (content) alert(content);
}

export const showAlert = (message) => {
    // return alert('Ошибка!', message, [{text: 'Ok'}]);
    // return alert('Ошибка!', message, [{text: 'Ok'}]);
}

export const showMessage = (title = "", message = "") => {
    // return alert(title, message, [{text: 'Ok'}]);
}
