import React from "react";
import {Grid} from '@material-ui/core';
import {ICabinet} from "../index.d";

type FormField = {
    label: string,
    name: string,
}

const formFields: FormField[] = [
    {label: "Имя", name: "name"},
    {label: "Фамилия", name: "surname"},
    {label: "Email", name: "email"},
    {label: "Телефон", name: "phone"},
    {label: "Компания", name: "company"},
]

interface FormValues {
    [index: string]: string,
    name: string,
    surname: string,
    company: string,
    phone: string,
    email: string,
}

interface StateForm {
    isEdit: boolean,
    disabled: boolean,
    formValues: FormValues
}

class Form extends React.Component<ICabinet, StateForm> {

    readonly state = {
        isEdit: false,
        disabled: false,
        formValues: {
            name: "",
            surname: "",
            company: "",
            phone: "",
            email: "",
        }
    }

    componentDidMount = () => {
        const {user} = this.props;
        let formValues: FormValues = Object.assign({}, this.state.formValues);
        formFields.forEach((val: FormField) => {
            formValues[val.name] = user[val.name]
        });
        this.setState({formValues})
    }

    setEdit = (type: boolean): void => {
        this.setState({isEdit: type});
    }

    onChangeField = (name: string, event: any) => {
        let formValues: FormValues = Object.assign({}, this.state.formValues);

        if (formValues[name] !== undefined) {
            formValues[name] = event.target.value;
            console.log(formValues[name]);

            this.setState({formValues})
        }
    }

    onSubmit = () => {
        const self = this,
            {fnSave} = self.props,
            {formValues} = self.state;

        self.setState({disabled: true}, () => {
            if (fnSave) fnSave(formValues, (isError: boolean) => {
                if (!isError) self.setState({isEdit: false, disabled: false})
            })
        })
    }

    render = () => {
        const self = this,
            {isEdit, formValues, disabled}: StateForm = self.state,
            {user} = this.props;

        return (
            <>
                <div className="form-fields">
                    {formFields.map((field: FormField, idx: number) => (
                        <Grid key={idx} container alignItems="center" className="form-field">
                            <Grid item xs={3}>
                                <label
                                    className={disabled?"disabled":""}>
                                    {field.label}
                                </label>
                            </Grid>
                            <Grid item xs={9}>
                                {isEdit?(
                                    <input
                                        disabled={disabled}
                                        onChange={(event: any) => self.onChangeField(field.name, event)}
                                        name={field.name}
                                        value={formValues[field.name]}/>
                                ):(
                                    <span>{user[field.name]?user[field.name]:"-"}</span>
                                )}
                            </Grid>
                        </Grid>
                    ))}
                </div>
                <Grid
                    container
                    alignItems="center"
                    className="form-control"
                    spacing={3}>
                    <Grid item xs={6}>
                        <button
                            disabled={disabled}
                            className="change-form"
                            onClick={() => self.setEdit(!isEdit)}>
                            {isEdit?"Отменить":"Изменить"}
                        </button>
                    </Grid>
                    <Grid item xs={6}>
                        {isEdit?(
                            <button
                                disabled={disabled}
                                className="submit-form"
                                onClick={() => self.onSubmit()}>
                                Сохранить
                            </button>
                        ):null}
                    </Grid>
                </Grid>
            </>
        )
    }
}
export default Form;
