import React from "react";
import {Grid} from '@material-ui/core';
import {IAuthItemForm} from "../../../store/models/index.d";

type TForm = {
    onSubmit: any,
    valueChange: any,
    fields: IAuthItemForm[]
}

type TFormField = {
    field: IAuthItemForm,
    valueChange: any
}

const FormField = ({field, valueChange}: TFormField) => {
    const [value, setValue] = React.useState(field.value),
        onChange = (event: any) => {
            let value = event.target.value;
            setValue(value);
            valueChange(field.name, value)
        }
        
    return (
        <Grid container alignItems="center" className="form-field">
            <Grid item xs={3}>
                <label>{field.label}</label>
            </Grid>
            <Grid item xs={9}>
                <input
                    onChange={onChange}
                    name={field.name}
                    type={field.type}
                    value={value} />
            </Grid>
        </Grid>
    )
}

const Form: React.FC<TForm> = ({onSubmit, fields, valueChange, children}) => {
    return (
        <form onSubmit={onSubmit}>
            {fields.map((val: IAuthItemForm, idx: number) => (
                <FormField
                    key={idx}
                    field={val}
                    valueChange={valueChange}/>
            ))}
            {children}
        </form>
    )
}

export default Form;
