import React from "react";
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import {TFilterData, Option} from "../index.d"; // <= getCityOption

type Props = {
    search: string,
    filterData: TFilterData,
    onChange: any,
    onChangeText: any
}

const CityAuthoSelect: React.FC<Props> = ({search, filterData, onChange, onChangeText}) => {
    const [open, setOpen] = React.useState<boolean>(false);

    const {
        getRootProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
    } = useAutocomplete({
        id: 'use-autocomplete-city',
        options: filterData.cities,
        getOptionLabel: (option: Option) => option.name?option.name:"",
        disableClearable: true,
        onChange: (event: any, newValue: any) => onChange(event, newValue),
        // defaultValue: getCityOption(filterData, search),
        inputValue: search,
        // value: getCityOption(filterData, search),
        // onBlur: () => console.log(111)
        open,
        openOnFocus: true
    }),
    inputProps: any = getInputProps(),
    divProps: any = getRootProps();

    return (
        <div className="select-city">
            <div {...divProps} className="select-section"
            onClick={() => {
                inputProps.ref.current.focus()
                setOpen(true)
            }}>
                <input className="select-city-input" 
                    {...inputProps}
                    onFocus={() => onChangeText({}, true)}
                    onBlur={() => {
                        setOpen(false)
                    }}
                    value={search}
                    onChange={onChangeText} />
            </div>
            <div className="options-section">
                {groupedOptions.length > 0 ? (
                    <ul className="select-city-listbox" {...getListboxProps()}>
                        {groupedOptions.map((option: any, index: any) => (
                            <li key={option.id} {...getOptionProps({option, index})}>{option.name}</li>
                        ))}
                    </ul>
                ) : null}
            </div>
        </div>
    )
}

export default CityAuthoSelect;
