import React, {Component} from "react";
import {connect} from "react-redux";
import {Checkbox, IconButton} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    ICalculateMediaPlan_Items as IData,
    ICalculateMediaPlan_ChangesData as IChangesData,
    ICalculateMediaPlan_ChangeData as IChangeData
} from "store/models/index.d";
import {Grid} from 'react-virtualized';
import Plain from "./plain";
import ModifyDescription from "./modifyDescription";

type Props = {
    changesData: IChangesData,
    fnChangesData: any,
    data: IData[],
    columns: any[],
    getColumnWidth: any,
    rowHeight: number,
    width: number,
    defaultValue: number,
}

enum InputChangeType {
    minus,
    plus
}

interface IHandleInput {
    id: number,
    changeData: IChangeData,
    fileld: string,
    step: number,
    min: number,
    max: number,
    type: InputChangeType
}

const InputNumber = ({
    value,
    onPlus, onMinus
}:{
    value: number,
    onPlus: any, onMinus: any
}) => {
    return (
        <span className="input-number">
            <button onClick={onMinus} className="minus" />
            <input disabled value={value} />
            <button onClick={onPlus} className="plus" />
        </span>
    )
}

class RowCell extends Component<Props> {

    getChangesData = (row: IData): any => {
        const {changesData} = this.props;
        let changeData: any = {
            selected: false,
            amount: row.amount.min,
            due: row.due.min,
            price: row.price,
            data: row
        }
        if (changesData[row.id]) changeData = changesData[row.id];

        return changeData;
    }

    handleSelected = (checked: boolean, id: number, changeData: IChangeData) => {
        changeData.selected = checked;
        this.props.fnChangesData({id, changeData});
    }

    handleInput = (data: IHandleInput) => {
        const {id, fileld, step, min, max, type} = data;
        let changeData = Object.assign({}, data.changeData);
        if (type === InputChangeType.minus) {
            changeData[fileld] -= step;
        } else if (type === InputChangeType.plus && changeData[fileld] < max) {
            changeData[fileld] += step;
        }
        if (changeData[fileld] < min) changeData[fileld] = min;
        this.props.fnChangesData({id, changeData});
    }

    getHandleInputData = (row: IData, name: string, changeData: IChangeData): IHandleInput => {
        return {
            id: row.id,
            changeData,
            fileld: name,
            step: row[name].step,
            min: row[name].min,
            max: row[name].max,
            type: InputChangeType.plus
        }
    }

    cellRenderer = (grid: any, columns: any, dataSource: IData[]) => {
        let value: any = null;
        const self = this,
            {columnIndex, rowIndex, key, style} = grid,
            column: any = columns[columnIndex],
            row: IData = dataSource[rowIndex];

        let className = "GridColumn", classNameSpan = "";
        if (rowIndex === dataSource.length - 1) {
            className += " border-bottom-0";
        }

        if (column && row) {
            const changeData: IChangeData = self.getChangesData(row);
            if (changeData.selected) className += " selected";

            switch (column.name) {
                case "region":
                case "city":
                case "channel":
                case "serviceType":
                case "mediaType":
                    if (row[column.name]) {
                        return (
                            <div key={key} style={style} className={className}>
                                <Plain value={row[column.name].name} />
                            </div>
                        )
                    }
                    break;
                case "amount":
                    const defamount = self.getHandleInputData(row, "amount", changeData);
                    if (defamount.step) {
                    value = <InputNumber
                        onPlus={() => self.handleInput({...defamount})}
                        onMinus={() => self.handleInput({...defamount, type: InputChangeType.minus})}
                        value={changeData.amount}/>
                    } else {
                        classNameSpan += "text-center";
                        value = changeData.amount;
                    }
                    break;
                case "due":
                    const defdue = self.getHandleInputData(row, "due", changeData);
                    if (defdue.step) {
                        value = <InputNumber
                            onPlus={() => self.handleInput({...defdue})}
                            onMinus={() => self.handleInput({...defdue, type: InputChangeType.minus})}
                            value={changeData.due} />
                    } else {
                        classNameSpan += "text-center";
                        value = changeData.due;
                    }
                    break;
                case "checkbox":
                    className += " p-0";
                    classNameSpan += "text-center";
                    value = (
                        <Checkbox
                            onChange={(e: any) => self.handleSelected(e?.target?.checked, row.id, changeData)}
                            checked={changeData.selected}
                            color="primary"/>
                    )
                    break;
                case "delete":
                    className += " p-0";
                    classNameSpan += "text-center";
                    value = (
                        <IconButton
                            onClick={() => self.handleSelected(false, row.id, changeData)}>
                            <DeleteIcon />
                        </IconButton>
                    )
                    break;
                case "sale":
                    className += " p-0";
                    if (row[column.name]) value = <span className="fire"></span>;
                    break;
                case "price":
                    value = (changeData.price).toLocaleString('ru-RU');
                    break;
                default:
                    if (row[column.name]) {
                        if (column.name === "name") {
                            return (
                                <ModifyDescription
                                    key={key}
                                    column={column}
                                    row={row}
                                    style={style}
                                    className={className}/>
                            )
                        }
                        return (
                            <div key={key} style={style} className={className}>
                                <Plain value={row[column.name]} />
                            </div>
                        )
                    }
                    break;
            }
        }

        return (
            <div key={key} style={style} className={className}>
                <span className={classNameSpan}>{value}</span>
            </div>
        )
    }

    render = () => {
        const self = this,
            {data, columns, getColumnWidth, rowHeight, width, defaultValue} = self.props;

        let gridHeight = data.length * rowHeight;
        if (gridHeight>500) gridHeight = 500;

        return (
            <div className="table-body">
                <Grid
                    className="GridRow"
                    overscanColumnCount={0}
                    overscanRowCount={10}
                    cellRenderer={(grid: any) => self.cellRenderer(grid, columns, data)}
                    columnWidth={(grid: any) => getColumnWidth(grid, columns, defaultValue)}
                    columnCount={columns.length}
                    rowHeight={rowHeight}
                    rowCount={data.length}
                    height={gridHeight}
                    width={width} />
            </div>
        )
    }
}

const mapState = (state: any) => {
    return {
        changesData: state.calculateMediaPlan.changesData
    };
},
mapDispatch = (dispatch: any) => {
    return {
        fnChangesData: (data: any) => dispatch.calculateMediaPlan.changesData(data),
    };
}

export default connect(mapState, mapDispatch)(RowCell);
